import React, { useCallback, useEffect, useState } from "react";
import useLocalized from "../../Data/Localization";
import { connect, useDispatch } from "react-redux";
import { FAIL, LOADING, PDF_FAIL, PDF_LOADING, PDF_SUCCESS, SUCCESS } from "../../Redux/ActionTypes";
import {
    createPdf,
    getWeeklyRecord,
    sendWeeklyRecordPDFEmail,
    setStateToDefault
} from "../../Redux/Actions/WeeklyRecordActions";
import TopTitle from "../../Components/TopTitle/TopTitle";
import DateService from "../../Services/DateService";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import WeeklyRecordList from "../../Components/WeeklyRecordList/WeeklyRecordList";
import ErrorIcon from "@material-ui/icons/Error";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorMessage from "../../Components/ErrorMessage/ErrorMessage";
import "react-day-picker/lib/style.css";
import WeekSelector from "../../Components/WeekSelector/WeekSelector";
import { getPartners } from "../../Redux/Actions/PartnerActions";
import { setPartnerData } from "../../Redux/Actions/PartnerDataAction";
import { showSnackbar } from "../../Redux/Actions/SnackbarActons";

const WeeklyRecord = ({
    weeklyRecord: {
        status: weeklyRecordStatus,
        emailStatus: weeklyRecordEmailStatus,
        downloadStatus: weeklyRecordDownloadStatus,
        weeklyRecord,
    },
    partners: {
        status: partnerStatus,
        data: partnerData,
    },
    getWeeklyRecord,
    setStateToDefault,
    createPdf,
    getPartners,
    sendWeeklyRecordPDFEmail,
    setPartnerData
}) => {

    const [currentDate] = useState(new Date());
    const [dateFrom, setStartSelectedDate] = useState(DateService.convertDate(DateService.getWeekStartDate(currentDate)));
    const [dateTo, setEndSelectedDate] = useState(DateService.convertDate(DateService.getWeekEndDate(currentDate)));

    const currentWeek = DateService.getCurrentWeekNumber(DateService.getCurrentDate());
    const [selectedWeek, setSelectedWeek] = useState(currentWeek);

    const [state, setState] = useState({
        hoverRange: undefined,
        selectedDays: [],
    });
    const dispatch = useDispatch();

    useEffect(() => {
        setTimeout(() => {
            getWeeklyRecord(dateFrom, dateTo);
            getPartners();
        }, 0);
    }, [dateFrom, dateTo, getWeeklyRecord, getPartners]);

    useEffect(() => {
        if (weeklyRecordEmailStatus === PDF_SUCCESS){
            dispatch(showSnackbar("Email successfully sent", "success"));
        }
        if ( weeklyRecordEmailStatus === PDF_FAIL) {
            dispatch(showSnackbar("Error sending email", "error"));
        }
    }, [dispatch, weeklyRecordEmailStatus]);

    const updateWeekSelection = useCallback((weekNumber, days) => {
        setSelectedWeek(weekNumber);
        setStartSelectedDate(DateService.convertDate(days[0]));
        setEndSelectedDate(DateService.convertDate(days[6]));
        setStateToDefault();
        setState({
            selectedDays: days,
        });
    }, [setStateToDefault]);

    const { hoverRange, selectedDays } = state;

    const daysAreSelected = selectedDays.length > 0;

    const modifiers = {
        hoverRange,
        selectedRange: daysAreSelected && {
            from: selectedDays[0],
            to: selectedDays[6],
        },
        hoverRangeStart: hoverRange && hoverRange.from,
        hoverRangeEnd: hoverRange && hoverRange.to,
        selectedRangeStart: daysAreSelected && selectedDays[0],
        selectedRangeEnd: daysAreSelected && selectedDays[6],
    };

    const handleDownloadWeek = (dateFrom, dateTo, partnerIds) => {
        createPdf(dateFrom, dateTo, partnerIds);
    };

    const sendEmailAction = (dateFrom, dateTo, partnerId) => {
        sendWeeklyRecordPDFEmail(dateFrom, dateTo, partnerId);
    };

    const savePartnerData = useCallback(
        (partner, partnerId) => {
            setPartnerData(partnerId, partner, () => {
                getPartners();
            });
        },
        [getPartners, setPartnerData]
    );

    return (
        <>
            <TopTitle title={`${useLocalized("timesheet_title")}`} subtitle={`${useLocalized("invoice_subtitle")}`} />
            <Grid container>
                <div className="week-select-section">
                    <Grid item xs={12} className="start-end-date-send-button">
                        <WeekSelector
                            selectedWeek={selectedWeek}
                            selectedDays={selectedDays}
                            dateFrom={dateFrom}
                            dateTo={dateTo}
                            modifiers={modifiers}
                            updateWeekSelection={updateWeekSelection}
                        />
                    </Grid>
                </div>
            </Grid>
            {(weeklyRecordStatus === SUCCESS || weeklyRecordStatus === PDF_SUCCESS) &&
                <WeeklyRecordList
                    weeklyRecord={weeklyRecord}
                    weekNumber={selectedWeek}
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    handleDownloadWeek={handleDownloadWeek}
                    emailAction={sendEmailAction}
                    partnerContacts={partnerData.partnersData}
                    partnerSaveAction={savePartnerData}
                    weeklyRecordEmailStatus={weeklyRecordEmailStatus}
                    weeklyRecordDownloadStatus={weeklyRecordDownloadStatus}
                />}
            {(weeklyRecordStatus === LOADING || weeklyRecordStatus === PDF_LOADING) && <div className="loading-icon"><CircularProgress /></div>}
            {(weeklyRecordStatus === FAIL || weeklyRecordStatus === PDF_FAIL) && <div className="no-data-error-text"><ErrorIcon /><ErrorMessage /></div>}
        </>
    );
};

WeeklyRecord.propTypes = {
    weeklyRecord: PropTypes.object,
    getWeeklyRecord: PropTypes.func,
    createPdf: PropTypes.func,
    setStateToDefault: PropTypes.func,
};

export default connect(({
    weeklyRecord,
    partners
}) => ({
    weeklyRecord,
    partners
}), {
    getWeeklyRecord,
    createPdf,
    setStateToDefault,
    getPartners,
    sendWeeklyRecordPDFEmail,
    setPartnerData
})(WeeklyRecord);
