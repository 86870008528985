import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { LocalizedContext } from './Services/LocalizationService';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import './App.scss';
import Login from './Screens/Login/Login';
import Dashboard from './Components/Dashboard/Dashboard';
import PrivateRoute from './Components/PrivateRoute/PrivateRoute';
import WebService from './Services/WebServices';
import DateFnsUtils from '@date-io/date-fns';
import { MuiThemeProvider } from "@material-ui/core/styles";
import theme from './Styles/theme';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { setLoginPageToDeafult } from './Redux/Actions/LoginAction';
import CustomSnackbar from './Components/CustomSnackbar/CustomSnackbar';
import { useSelector, useDispatch } from 'react-redux';
import { hideSnackbar } from './Redux/Actions/SnackbarActons';

function App({ locale, token, setLoginPageToDeafult }) {

  useEffect(() => {
    if (token !== '') {
      WebService.getSharedInstance().setToken(token);
    }
  }, [token]);

  const { open, message, severity } = useSelector(state => state.snackbar);
  const dispatch = useDispatch();

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    dispatch(hideSnackbar());
  };

  return (
    <BrowserRouter>
      <LocalizedContext.Provider value={locale}>
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Route path="/login" onLeave={setLoginPageToDeafult()} component={Login} />
            <Route exact path="/">
              {token ? <Redirect to="/drivers" /> : <Redirect to="/login" />}
            </Route>
            <PrivateRoute path='/drivers' component={Dashboard} />
            <PrivateRoute path='/schedule' component={Dashboard} />
            <PrivateRoute path='/partners' component={Dashboard} />
            <PrivateRoute path='/orders' component={Dashboard} />
            <PrivateRoute path='/timesheets' component={Dashboard} />
            <PrivateRoute path='/invoice' component={Dashboard} />
            <PrivateRoute path='/weekly-record' component={Dashboard} />
            <PrivateRoute path='/driver/:driverId' component={Dashboard} />
            <PrivateRoute path='/job/:scheduleId' component={Dashboard} />
            <CustomSnackbar
              open={open}
              message={message}
              severity={severity}
              onClose={handleSnackbarClose}
            />
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </LocalizedContext.Provider>
    </BrowserRouter>
  );
}

const mapStateToProps = (state) => {
  const {
    user: { locale, token },
  } = state.user;
  return { locale, token };
};


export default connect(mapStateToProps, { setLoginPageToDeafult })(App);
