import React, { useCallback, useEffect, useMemo, useState } from "react";
import useLocalized from "../../Data/Localization";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TopTitle from "../../Components/TopTitle/TopTitle";
import ErrorMessage from "../../Components/ErrorMessage/ErrorMessage";
import { createPartner, getPartners } from "../../Redux/Actions/PartnerActions";
import { setPartnerData } from "../../Redux/Actions/PartnerDataAction";
import { FAIL, LOADING, SUCCESS } from "../../Redux/ActionTypes";
import PartnerList from "../../Components/PartnerList/PartnerList";
import ErrorIcon from "@material-ui/icons/Error";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormHelperText from "@material-ui/core/FormHelperText";
import PartnerPopup from "../../Components/PartnerPopup/PartnerPopup";

const Partners = ({
    partners: {
        status: partnerStatus,
        data: partnerData,
    },
    getPartners,
    createPartner,
    setPartnerData
}) => {

    // region States

    const [presentedPartners, setPresentedPartners] = useState([]);
    const [successMessage, setSuccessMessage] = useState("");
    const [open, setOpen] = useState(false);
    const [partnersToSave, setPartnersToSave] = useState([]);

    // endregion

    // region UseMemo hooks

    const canSave = useMemo(() => partnersToSave.length > 0, [partnersToSave]);

    // endregion

    // region Custom hooks

    const dataSavedMessage = useLocalized("partners_data_successfully_updated");

    // endregion

    // region Functions

    /**
     * Sorts a list of partners alphabetically based on their `id`.
     *
     * @param {Array<Object>} partners - The list of partners to be sorted.
     * @returns {Array<Object>} The sorted list of partners.
     */
    const sortPartnerList = useCallback((partners) => {
        return partners.sort(({ id: a }, { id: b }) => a.localeCompare(b));
    }, []);

    // endregion

    // region Handler functions

    /**
     * Saves a new partner by calling the `createPartner` function.
     *
     * @param {Object} createPartnerData - The data of the partner to be created.
     */
    const handleSave = useCallback((createPartnerData) => {
        createPartner(createPartnerData);
    }, [createPartner]);

    /**
     * Toggles the active state of a partner and updates the backend.
     * After updating, it fetches the latest partner data.
     *
     * @param {string | number} partnerId - The unique identifier of the partner whose active state is being toggled.
     */
    const handlePartnerActiveState = useCallback((partnerId) => {
        const selectedPartner = presentedPartners.find((partner) => partner.id === partnerId);

        if (!selectedPartner) return; // Prevents errors if the partner is not found

        setPartnerData(partnerId, { is_active: !selectedPartner.isActive }, () => {
            getPartners();
        });
    }, [getPartners, presentedPartners, setPartnerData]);

    /**
     * Updates or adds a partner's data in the `partnersToSave` state.
     *
     * @param {string | number} partnerId - The unique identifier of the partner.
     * @param {string} columnId - The column ID representing the field being updated.
     * @param {number} rowIndex - The index of the row (not used in this function but available for future use).
     * @param {any} value - The new value to update for the specified column.
     */
    const handlePartnerDataChange = useCallback((partnerId, columnId, rowIndex, value) => {
        // Check if the partner exists in partnersToSave array
        const existingPartnerIndex = partnersToSave.findIndex((partner) => partner.partnerId === partnerId);

        if (existingPartnerIndex !== -1) {
            // If partner exists, update its data
            const updatedPartners = [...partnersToSave];
            updatedPartners[existingPartnerIndex] = {
                ...updatedPartners[existingPartnerIndex],
                [columnId]: value
            };
            setPartnersToSave(updatedPartners);
        } else {
            // If partner does not exist, add a new entry
            setPartnersToSave([...partnersToSave, { partnerId, [columnId]: value }]);
        }
    }, [partnersToSave]);

    /**
     * Saves partner data by converting camelCase keys to snake_case and making API calls for each partner.
     * After successfully saving, it clears the `partnersToSave` state and sets a success message.
     */
    const handlePartnerDataSaveApiCall = useCallback(() => {
        if (partnersToSave.length === 0) return;

        const formattedPartners = partnersToSave.map(({ partnerId, ...partner }) => {
            // Mapping camelCase keys to snake_case
            const editedPartner = Object.entries(partner).reduce((acc, [key, value]) => {
                const snakeCaseKey = key.replace(/([A-Z])/g, "_$1").toLowerCase();
                acc[snakeCaseKey] = value;
                return acc;
            }, {});

            return { partnerId, editedPartner };
        });

        // Process each partner update
        formattedPartners.forEach(({ partnerId, editedPartner }) => {
            setPartnerData(partnerId, editedPartner, () => {
                setSuccessMessage(dataSavedMessage);
            });
        });

        // Clear state once all updates are processed
        setPartnersToSave([]);
    }, [partnersToSave]);

    // endregion

    // region UseEffect hooks

    useEffect(() => {
        getPartners();
    }, [getPartners]);

    useEffect(() => {
        if (partnerData.partnersData) {
            setPresentedPartners(sortPartnerList(partnerData.partnersData));
        }
    }, [sortPartnerList, partnerData]);

    // endregion

    return (
        <>
            <div className='title-container'>
                <TopTitle title={`${useLocalized("partners_title")}`} subtitle={`${useLocalized("partners_subtitle")}`} />
                <div>
                    <button className='partner-buttons' disabled={!canSave} onClick={handlePartnerDataSaveApiCall}>
                        {useLocalized("save_button_text")}
                    </button>
                    <button className='partner-buttons' onClick={() => setOpen((oldState) => !oldState)}>
                        {useLocalized("new_partner_button_text")}
                    </button>
                </div>
            </div>
            {<PartnerPopup action={handleSave} handleOpenClose={() => setOpen((oldState) => !oldState)} dialogOpen={open}/>}
            <FormHelperText component="div"><div className="saved-successfully-data-text partner-saved-text">{successMessage}</div></FormHelperText>
            {partnerStatus === LOADING && <div className="loading-icon"><CircularProgress /></div>}
            {partnerStatus === SUCCESS && <PartnerList presentedPartners={presentedPartners} handlePartnerDataChange={handlePartnerDataChange} handlePartnerActiveState={handlePartnerActiveState} />}
            {partnerStatus === FAIL && <div className="no-data-error-text"><ErrorIcon /><ErrorMessage /></div>}
        </>
    );
};

Partners.propTypes = {
    partners: PropTypes.object,
    getPartners: PropTypes.func,
    setPartnerData: PropTypes.func,
    createPartner: PropTypes.func,
};

export default connect(({
    partners,
}) => ({
    partners,
}), {
    getPartners,
    createPartner,
    setPartnerData
})(Partners);
