import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import DateService from "../../Services/DateService";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import useLocalized from "../../Data/Localization";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import EventNoteIcon from "@material-ui/icons/EventNote";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import SettingsGear from "../../Assets/settings-gear.svg";
import EmailSendIcon from "../../Assets/email-send-icon.svg";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

import XMark from "../../Assets/xmark.svg";
import Modal from "react-modal";
import { PDF_FAIL, PDF_LOADING, PDF_SUCCESS } from "../../Redux/ActionTypes";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";
import { RotateRight } from "@material-ui/icons";
import { showSnackbar } from "../../Redux/Actions/SnackbarActons";
import { useDispatch } from "react-redux";


// region Style definitions

const useStyles = makeStyles({
    root: {
        width: "100%",
    },
    accordion: {
        backgroundColor: "white",
        boxShadow: "0px 0px 6px 4px rgba(0,0,0,0.04)",
        width: "99.3%",
    },
    accordionContent: {
        margin: "0"
    },
    table: {
        minWidth: 1700,
    },
    secondaryButton: {
        backgroundColor: "#FF8C00",
        color: "#ffffff",
        borderRadius: 0,
        "&:hover": {
            backgroundColor: "#FFA500",
        },
        "&:disabled": {
            backgroundColor: "#B8B8B8"
        }
    }
});

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "90%",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
};

// endregion

const WeeklyRecordList = ({
    weeklyRecord,
    weekNumber,
    dateFrom,
    dateTo,
    handleDownloadWeek,
    partnerContacts,
    emailAction,
    partnerSaveAction,
    weeklyRecordEmailStatus,
    weeklyRecordDownloadStatus
}) => {
    const classes = useStyles();

    // region Translations

    const weeklyRecordWarehouseLabel = useLocalized("invoice_warehouse_label");
    const detailsLabel = useLocalized("details_button");
    const driversLabel = useLocalized("nav_drivers");
    const vehicleRegNr = useLocalized("vehicle_reg_number");
    const managerLabel = useLocalized("job_tracking_manager_label");
    const startTimeLabel = useLocalized("start_time_label");
    const finishTimeLabel = useLocalized("finish_time_label");
    const noWeekRecord = useLocalized("no_weekly_record");
    const workHoursLabel = useLocalized("work_hours_label");
    const overtimeLabel = useLocalized("overtime_label");
    const driverSignatureLabel = useLocalized("driver_signature_label");
    const managerSignatureLabel = useLocalized("manager_signature_label");
    const taxZoneLabel = useLocalized("tax_zone_label");
    const pdfDownloadSuccessMessage = useLocalized("pdf_download_success_message");
    const pdfDownloadErrorMessage = useLocalized("pdf_download_error_message");
    const pdfDownloadLoadingMessage = useLocalized("pdf_download_loading_message");

    // endregion

    // region States

    const [openPopUp, setOpenPopUp] = useState(false);
    const [openSettingsPopUp, setOpenSettingsPopUp] = useState(false);
    const [emails, setEmails] = useState([]);
    const [temporaryEmails, setTemporaryEmails] = useState([]);
    const [selectedWeeklyRecord, setSelectedWeeklyRecord] = useState();
    const [selectedPartners, setSelectedPartners] = useState([]);

    // endregion

    // region Helper functions

    const selectAll = useCallback(
        (e) => {
            setSelectedPartners(e.target.checked ? weeklyRecord.map(({ partner_id }) => partner_id) : []);
        }, [weeklyRecord]
    );

    const selectItem = useCallback(
        (e, id) => {
            setSelectedPartners((current) =>
                e.target.checked
                    ? [...current, id]
                    : current.filter(partner_id => partner_id !== id)
            );
        }, []
    );

    const checkIfSelected = useCallback((id) => {
        return selectedPartners.includes(id);
    }, [selectedPartners]);

    // endregion

    // region Handler functions

    const handleDownload = useCallback(() => {
        handleDownloadWeek(dateFrom, dateTo, selectedPartners);
    }, [dateFrom, dateTo, handleDownloadWeek, selectedPartners]);

    const handleEmailSending = useCallback((partnerId) => {
        emailAction(dateFrom, dateTo, partnerId);
    }, [dateFrom, dateTo, emailAction]);

    const handleEmailSendingToSelectedPartners = useCallback((selectedPartnerIds) => {
        selectedPartnerIds.forEach(partnerId =>
            emailAction(dateFrom, dateTo, partnerId)
        );
        setSelectedPartners([]);
    }, [dateFrom, dateTo, emailAction]);

    const handleEmailChange = useCallback((index, value) => {
        const updatedEmails = [...temporaryEmails];
        updatedEmails[index] = value;
        setTemporaryEmails(updatedEmails);
    }, []);

    const handleAddEmailField = useCallback(() => {
        if (temporaryEmails.length < 5) {
            setTemporaryEmails((prevEmails) => [...prevEmails, ""]);
        }
    }, []);

    const handleRemoveEmailField = useCallback((index) => {
        const updatedEmails = [...temporaryEmails];
        updatedEmails.splice(index, 1);
        setTemporaryEmails(updatedEmails);
    }, []);

    const handleSettingsClick = useCallback((weeklyRecordItems) => {
        setOpenSettingsPopUp(true);
        setSelectedWeeklyRecord(weeklyRecordItems);
    }, []);

    const handlePartnerUpdate = useCallback((partnerId) => {
        const filteredEmails = temporaryEmails.filter((email) => email);
        setEmails(filteredEmails);
        partnerSaveAction({ partner_contact_emails: filteredEmails }, partnerId);
        setOpenSettingsPopUp(false);
    }, [temporaryEmails, partnerSaveAction]);

    const handleClose = useCallback(() => {
        setOpenSettingsPopUp(false);
        setTemporaryEmails([...emails]);
    }, []);

    // endregion

    // region UI Builder functions

    const renderEmailInputs = useCallback(() => {
        return temporaryEmails.map((email, index) => (
            <div className='add-email-textfield-container' key={index}>
                <input
                    className="email-textfield"
                    type="email"
                    placeholder="Enter email"
                    name={`partner_contact_emails${index}`}
                    value={email}
                    onChange={(e) => handleEmailChange(index, e.target.value)}
                />
                {temporaryEmails.length > 1 && (
                    <button
                        className="remove-email-button"
                        onClick={() => handleRemoveEmailField(index)}
                    >
                        <img
                            alt='XMark'
                            src={XMark}
                        />
                    </button>
                )}
            </div>
        ));
    }, []);

    const buildEmailList = useCallback((weeklyRecordItem) => {
        if (!partnerContacts || partnerContacts.length === 0) {
            return <Typography style={{ paddingLeft: 20 }}>No emails available</Typography>;
        }
        const partner = partnerContacts.find(
            element => element.id === weeklyRecordItem.partner_id
        );

        if (!partner || !partner.partnerContactEmails) {
            return (
                <Typography style={{ paddingLeft: 20 }}>
                    No emails found for the selected partner.
                </Typography>
            );
        }
        return partner.partnerContactEmails.map((item, index) => (
            <Typography style={{ paddingLeft: 20 }} key={index}>
                {item.email}
            </Typography>
        ));
    }, []);

    const buildEmailModal = useCallback(() => {
        if (!selectedWeeklyRecord) return null;

        return (
            <Modal
                style={customStyles}
                isOpen={openPopUp}
                onRequestClose={() => setOpenPopUp(false)}
            >
                <div className='popUpContainer'>
                    { weeklyRecordEmailStatus === PDF_LOADING && (
                        <>
                            <div className="loading-container" />
                            <CircularProgress className="loading-icon"/>
                        </>
                    )}

                    <img src={EmailSendIcon} alt="EmailSendIcon" />
                    <div className='weekly-record-send-message'>
                        Are you sure you want to send weekly report:
                    </div>
                    <div className='weekly-record-send-text-partner'>
                        {`W${weekNumber} ${selectedWeeklyRecord.partner_name ?? ""} ${selectedWeeklyRecord.partner_location}`}
                    </div>
                    <div className='weekly-record-send-text'>
                        to
                    </div>
                    <div className='weekly-record-send-text-emails'>
                        {buildEmailList(selectedWeeklyRecord)}
                    </div>
                    <div className='weekly-record-send-button-container'>
                        <button
                            className='weekly-record-send-button-yes'
                            onClick={() => { handleEmailSending(selectedWeeklyRecord.partner_id); }}
                        >
                            Yes
                        </button>
                        <button
                            className='weekly-record-send-button-no'
                            onClick={() => setOpenPopUp(false)}
                        >
                            No
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }, [buildEmailList, handleEmailSending, openPopUp, selectedWeeklyRecord, weekNumber, weeklyRecordEmailStatus]);

    const buildSettingModal = useCallback(() => {
        if (!selectedWeeklyRecord) return null;
        return (
            <Modal
                style={customStyles}
                isOpen={openSettingsPopUp}
                onRequestClose={handleClose}
            >
                <img
                    className='xmark'
                    alt='XMark'
                    src={XMark}
                    onClick={handleClose}
                />
                <div className='popUpContainer'>
                    <div className='title-container'>
                        <div>
                            <div className="title">Email manage</div>
                            <div className="sub-title">edit or add emails</div>
                        </div>
                    </div>

                    <div className="weekly-record-send-text-partner">Partner emails</div>

                    {renderEmailInputs()}

                    <div className='setting-modal-button-container'>
                        <button
                            className='button button--add-email'
                            disabled={emails.length >= 5}
                            onClick={handleAddEmailField}
                        >
                            Add Email Field
                        </button>

                        <button
                            className='button'
                            onClick={() => handlePartnerUpdate(selectedWeeklyRecord.partner_id)}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }, []);

    // endregion

    // region Custom hooks

    const dispatch = useDispatch();

    // endregion

    // region UseEffect calls

    useEffect(() => {
        if (weeklyRecordEmailStatus === PDF_SUCCESS) {
            setOpenPopUp(false);
        }
    }, [weeklyRecordEmailStatus]);

    useEffect(() => {
        if (selectedWeeklyRecord) {
            const partner = partnerContacts.find(
                (p) => p.id === selectedWeeklyRecord.partner_id
            );
            if (partner && partner.partnerContactEmails) {
                const partnerEmails = partner.partnerContactEmails.map((item) => item.email);
                setEmails(partnerEmails);
                setTemporaryEmails(partnerEmails);
            } else {
                setEmails([""]);
                setTemporaryEmails([""]);
            }
        }
    }, [selectedWeeklyRecord, openSettingsPopUp, partnerContacts]);

    useEffect(() => {
        if (weeklyRecordDownloadStatus === PDF_LOADING) {
            dispatch(showSnackbar(pdfDownloadLoadingMessage));
        }

        if (weeklyRecordDownloadStatus === PDF_SUCCESS) {
            dispatch(showSnackbar(pdfDownloadSuccessMessage, "success"));
        }

        if (weeklyRecordDownloadStatus === PDF_FAIL) {
            dispatch(showSnackbar(pdfDownloadErrorMessage, "error"));
        }

    }, [dispatch, pdfDownloadErrorMessage, pdfDownloadLoadingMessage, pdfDownloadSuccessMessage, weeklyRecordDownloadStatus]);

    // endregion

    // region Return component

    return (
        <>
            {buildSettingModal()}
            {buildEmailModal()}
            <div className="weekly-record-grid-style">
                <div className="weekly-record-action-buttons">
                    <Button
                        className={classes.secondaryButton}
                        startIcon={weeklyRecordDownloadStatus === PDF_LOADING ? (<RotateRight className="spin" />) : (<GetAppOutlinedIcon />)}
                        disabled={!selectedPartners.length}
                        onClick={handleDownload}
                    >
                        Download { selectedPartners.length ? `(${selectedPartners.length})` : "" }
                    </Button>
                    <Button
                        disabled={!selectedPartners.length}
                        startIcon={<MailOutlineIcon />}
                        onClick={() => {handleEmailSendingToSelectedPartners(selectedPartners);}}
                    >
                        Send { selectedPartners.length
                            ? selectedPartners.length === 1
                                ? `(${selectedPartners.length})`
                                : `all (${selectedPartners.length})`
                            : ""
                        }
                    </Button>
                </div>
                <Grid container spacing={1}>
                    <Grid item xs={1}>
                        <div className="week-nr-col">W{weekNumber}</div>
                    </Grid>
                    {weeklyRecord.length === 0 && <div className="no-data-message no-invoice-added"><EventNoteIcon />{noWeekRecord}</div>}
                    <Grid item xs={11}>
                        <div className="scrolling-wrapper-flexbox">
                            <div className={classes.root}>
                                {weeklyRecord.length > 0 &&
                                    <div className="weekly-record-header-section">
                                        <div className="warehouse-col">
                                            <div>
                                                <Checkbox
                                                    color="primary"
                                                    size="small"
                                                    id="allSelect"
                                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                                    onClick={selectAll}
                                                    checked={selectedPartners.length === weeklyRecord.length}
                                                />
                                                &nbsp;&nbsp;
                                                {weeklyRecordWarehouseLabel}
                                            </div>
                                            <div className="dot-tip-text">
                                                <div className="dot-tip" />
                                                <div>Includes extra taxes</div>
                                                <div className="dot-inactive-tip" />
                                                <div>No extra tax</div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {weeklyRecord.map((weeklyRecordItems, index) => (
                                    <Accordion className={classes.accordion} key={index}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <div className="warehouse-name">
                                                <Checkbox
                                                    color="primary"
                                                    size="small"
                                                    checked={checkIfSelected(weeklyRecordItems.partner_id)}
                                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                                    onChange={(e) => selectItem(e, weeklyRecordItems.partner_id)}
                                                />
                                                {weeklyRecordItems.has_pay_zones ? <div className="dot" /> : <div className="dot-inactive" />}
                                                {weeklyRecordItems.partner_name + " - " + weeklyRecordItems.partner_location}
                                            </div>
                                        </AccordionSummary>
                                        <div className="weekly-record-divider"><Divider /></div>
                                        <AccordionDetails>
                                            <TableContainer className="weekly-record-table">
                                                <Table className={classes.table} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow style={{ verticalAlign: "unset" }}>
                                                            <TableCell>
                                                                <div>{detailsLabel}</div>
                                                                <div className="date-range">{DateService.getSplitDate(weeklyRecordItems.date_from)["day"]}/{DateService.getSplitDate(weeklyRecordItems.date_from)["month"]} - {DateService.getSplitDate(weeklyRecordItems.date_to)["day"]}/{DateService.getSplitDate(weeklyRecordItems.date_to)["month"]}</div>
                                                            </TableCell>
                                                            <TableCell>{driversLabel}</TableCell>
                                                            <TableCell>{vehicleRegNr}</TableCell>
                                                            <TableCell style={{ verticalAlign: "unset", borderRightStyle: "solid", borderRightColor: "#DDDDDD", }}>{managerLabel}</TableCell>
                                                            <TableCell />
                                                            <TableCell>{startTimeLabel}</TableCell>
                                                            <TableCell>{finishTimeLabel}</TableCell>
                                                            <TableCell>{workHoursLabel}</TableCell>
                                                            <TableCell>{overtimeLabel}</TableCell>
                                                            <TableCell>{taxZoneLabel}</TableCell>
                                                            <TableCell>{managerSignatureLabel}</TableCell>
                                                            <TableCell style={{ verticalAlign: "unset", borderRightStyle: "solid", borderRightColor: "#DDDDDD", }}>{driverSignatureLabel}</TableCell>
                                                            <TableCell style={{ paddingLeft: 20 }}>email manager</TableCell>
                                                            <TableCell>
                                                                <img
                                                                    className='settings-gear'
                                                                    src={SettingsGear}
                                                                    alt="SettingsGear"
                                                                    onClick={() => handleSettingsClick(weeklyRecordItems)}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {weeklyRecordItems.daily_details.map((dailyDetailList, index) => (
                                                            <React.Fragment key={index}>
                                                                <TableRow>
                                                                    <TableCell style={{ verticalAlign: "unset" }}>
                                                                        {DateService.getDayName(dailyDetailList.date)}
                                                                    </TableCell>
                                                                    <TableCell style={{ verticalAlign: "unset" }}/>
                                                                    <TableCell style={{ verticalAlign: "unset" }}/>
                                                                    <TableCell style={{ verticalAlign: "unset", borderRightStyle: "solid", borderRightColor: "#DDDDDD", }}/>
                                                                    <TableCell />
                                                                    <TableCell />
                                                                    <TableCell />
                                                                    <TableCell />
                                                                    <TableCell />
                                                                    <TableCell />
                                                                    <TableCell />
                                                                    <TableCell style={{ verticalAlign: "unset", borderRightStyle: "solid", borderRightColor: "#DDDDDD", }}/>
                                                                    <TableCell />
                                                                </TableRow>
                                                                {dailyDetailList.details.map((detailItem, index) => (
                                                                    <TableRow key={index}>
                                                                        <TableCell style={{ verticalAlign: "unset" }}></TableCell>
                                                                        <TableCell style={{ verticalAlign: "unset" }}>
                                                                            <List>
                                                                                <ListItem>
                                                                                    <ListItemText
                                                                                        primary={`${detailItem.driver}`}
                                                                                    />
                                                                                </ListItem>
                                                                            </List>
                                                                        </TableCell>
                                                                        <TableCell style={{ verticalAlign: "unset" }}>
                                                                            <List>
                                                                                <ListItem>
                                                                                    <ListItemText
                                                                                        primary={`${detailItem.vehicle_reg_number !== null ? detailItem.vehicle_reg_number : "-"}`}
                                                                                    />
                                                                                </ListItem>
                                                                            </List>
                                                                        </TableCell>
                                                                        <TableCell style={{ verticalAlign: "unset",    borderRightStyle: "solid", borderRightColor: "#DDDDDD", }}>
                                                                            <List>
                                                                                <ListItem>
                                                                                    <ListItemText
                                                                                        primary={`${detailItem.manager_name !== null ? detailItem.manager_name : "-"}`}
                                                                                    />
                                                                                </ListItem>
                                                                            </List>
                                                                        </TableCell>
                                                                        <TableCell style={{ verticalAlign: "unset" }}>
                                                                            <ListItemText style={{ marginRight: 25 }} />
                                                                        </TableCell>
                                                                        <TableCell style={{ verticalAlign: "unset" }}>
                                                                            <List>
                                                                                <ListItem>
                                                                                    <ListItemText
                                                                                        primary={`${detailItem.start_time !== null ? DateService.getBritishTime(dailyDetailList.date, detailItem.start_time) : "-"}`}
                                                                                    />
                                                                                </ListItem>
                                                                            </List>
                                                                        </TableCell>
                                                                        <TableCell style={{ verticalAlign: "unset" }}>
                                                                            <List>
                                                                                <ListItem>
                                                                                    <ListItemText
                                                                                        primary={`${detailItem.finish_time !== null ? DateService.getBritishTime(dailyDetailList.date, detailItem.finish_time) : "-"}`}
                                                                                    />
                                                                                </ListItem>
                                                                            </List>
                                                                        </TableCell>
                                                                        <TableCell style={{ verticalAlign: "unset" }}>
                                                                            <List>
                                                                                <ListItem>
                                                                                    <ListItemText
                                                                                        primary={`${detailItem.work_hours !== null ? detailItem.work_hours : "-"}`}
                                                                                    />
                                                                                </ListItem>
                                                                            </List>
                                                                        </TableCell>
                                                                        <TableCell style={{ verticalAlign: "unset" }}>
                                                                            <List>
                                                                                <ListItem>
                                                                                    <ListItemText
                                                                                        primary={`${detailItem.overtime !== null ? detailItem.overtime : "-"}`}
                                                                                    />
                                                                                </ListItem>
                                                                            </List>
                                                                        </TableCell>
                                                                        <TableCell style={{ verticalAlign: "unset" }}>
                                                                            <List>
                                                                                <ListItem>
                                                                                    {detailItem.weekly_record_pay_zones.length > 0 ? <div className="dot" /> : <div className="dot-inactive" />}
                                                                                    <ListItemText
                                                                                        primary={`${detailItem.weekly_record_pay_zones.length > 0 ? detailItem.weekly_record_pay_zones.map(zone => zone.zone_name).join(", ") : "-"}`}
                                                                                    />
                                                                                </ListItem>
                                                                            </List>
                                                                        </TableCell>
                                                                        <TableCell style={{ verticalAlign: "unset" }}>
                                                                            <List>
                                                                                <ListItem>
                                                                                    <ListItemText
                                                                                        primary={`${detailItem.signature_path.length > 1 ? "Signed" : "-"}`}
                                                                                    />
                                                                                </ListItem>
                                                                            </List>
                                                                        </TableCell>
                                                                        <TableCell style={{ verticalAlign: "unset", borderRightStyle: "solid", borderRightColor: "#DDDDDD", }}>
                                                                            <List>
                                                                                <ListItem>
                                                                                    <ListItemText
                                                                                        primary={`${detailItem.driver_signature_path.length > 1? "Signed" : "-"}`}
                                                                                    />
                                                                                </ListItem>
                                                                            </List>
                                                                        </TableCell>
                                                                        <TableCell style={{ verticalAlign: "top" }}>
                                                                            {(dailyDetailList === weeklyRecordItems.daily_details[weeklyRecordItems.daily_details.length - 1] && detailItem === dailyDetailList.details[dailyDetailList.details.length - 1]) && (
                                                                                <>
                                                                                    {buildEmailList(weeklyRecordItems)}
                                                                                    <Button onClick={() => {
                                                                                        setOpenPopUp((oldValue) => !oldValue);
                                                                                        setSelectedWeeklyRecord(weeklyRecordItems);
                                                                                    }} style={{ margin: 20 }}>Send</Button>
                                                                                </>
                                                                            )}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </React.Fragment>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    );

    // endregion
};

WeeklyRecordList.propTypes = {
    weeklyRecord: PropTypes.object.isRequired,
    weekNumber: PropTypes.number.isRequired,
    dateFrom: PropTypes.string.isRequired,
    dateTo: PropTypes.string.isRequired,
    handleDownloadWeek: PropTypes.func.isRequired,
    partnerContacts: PropTypes.array.isRequired,
    emailAction: PropTypes.func.isRequired,
    partnerSaveAction: PropTypes.func.isRequired,
    weeklyRecordEmailStatus: PropTypes.string.isRequired,
    weeklyRecordDownloadStatus: PropTypes.string.isRequired
};

export default WeeklyRecordList;
