import WebService from '../../Services/WebServices';
import {
  WEEKLY_RECORD_RELOAD,
  WEEKLY_RECORD_LOADING,
  WEEKLY_RECORD_SUCCESS,
  WEEKLY_RECORD_FAILED,
  WEEKLY_RECORD_DOWNLOAD_FAILED,
  WEEKLY_RECORD_DOWNLOAD_LOADING,
  WEEKLY_RECORD_DOWNLOAD_SUCCESS,
  WEEKLY_RECORD_EMAIL_SEND_FAILED,
  WEEKLY_RECORD_EMAIL_SEND_SUCCESS,
  WEEKLY_RECORD_EMAIL_SEND_LOADING
} from '../ActionTypes';
import { WEEKLY_RECORD, WEEKLY_RECORD_PDF, PDF, REQUEST_ID } from '../../Constants/options';
import { API } from '../../Constants/options';
import { BASE_URL } from '../../Constants/config';

export const getWeeklyRecord = (dateFrom, dateTo) => async (dispatch) => {
  dispatch({ type:  WEEKLY_RECORD_LOADING });
  try {
    let options = {
      method: 'GET',
      path: `${WEEKLY_RECORD}?date_from=${dateFrom}&date_to=${dateTo}`,
    };
    const weeklyRecord = await WebService.getSharedInstance().callWebservice(
      options,
    );

    dispatch({
      type:  WEEKLY_RECORD_SUCCESS,
      payload: weeklyRecord

    });
  } catch (err) {
    dispatch({
      type:  WEEKLY_RECORD_FAILED,
      payload: {
        err,
      },
    });
  }
};

export const createPdf = (dateFrom, dateTo, partnerIds) => async (dispatch) => {
  dispatch({ type:  WEEKLY_RECORD_DOWNLOAD_LOADING });
  try {
    let optionsPost = {
      method: 'POST',
      path: `${WEEKLY_RECORD_PDF}${PDF}date_from=${dateFrom}&date_to=${dateTo}`,
      data: {partner_ids: partnerIds}
    };
    const request_id = await WebService.getSharedInstance().callWebservice(
      optionsPost,
    );

    fetch(`${BASE_URL}${API}${WEEKLY_RECORD_PDF}${PDF}${REQUEST_ID}${request_id.request_id}`, {
        method: 'GET',
        headers: new Headers({
            'Authorization': `Token ${WebService.getSharedInstance().token}`
        })
    })
      .then(response => {
        const contentDisposition = response.headers.get('Content-Disposition');

        let filename = 'weekly_records';

        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(/filename="(.+)"/);
          if (filenameMatch && filenameMatch[1]) {
            filename = filenameMatch[1];
          }
        }

        return response.blob().then(blob => ({ blob, filename }));
      })
      .then(({ blob, filename }) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });

    dispatch({
      type:  WEEKLY_RECORD_DOWNLOAD_SUCCESS,
    });

  } catch (err) {
    dispatch({
      type:  WEEKLY_RECORD_DOWNLOAD_FAILED,
      payload: {
        err,
      },
    });
  }
};

export const sendWeeklyRecordPDFEmail = (dateFrom, dateTo, partnerId) => async (dispatch) => {
  dispatch({ type:  WEEKLY_RECORD_EMAIL_SEND_LOADING });
  try {
    let optionsPost = {
      method: 'POST',
      path: `${WEEKLY_RECORD}/email/${partnerId}?date_from=${dateFrom}&date_to=${dateTo}`,
    };

    await WebService.getSharedInstance().callWebservice(
      optionsPost,
    );

    dispatch({
      type:  WEEKLY_RECORD_EMAIL_SEND_SUCCESS,
    });

  } catch (err) {
    dispatch({
      type:  WEEKLY_RECORD_EMAIL_SEND_FAILED,
      payload: {
        err,
      },
    });
  }
};

export const setStateToDefault = () => async (dispatch) => {
  dispatch({
    type: WEEKLY_RECORD_RELOAD
  });
};
