import React, { useState, useCallback, useEffect } from 'react';
import useLocalized from '../../Data/Localization';
import { SUCCESS, LOADING, FAIL } from '../../Redux/ActionTypes';
import TopTitle from '../../Components/TopTitle/TopTitle';
import Grid from '@material-ui/core/Grid';
import DateService from '../../Services/DateService';
import InvoiceSummary from '../../Components/InvoiceSummary/InvoiceSummary';
import { getInvoice } from '../../Redux/Actions/InvoiceActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ErrorIcon from '@material-ui/icons/Error';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorMessage from '../../Components/ErrorMessage/ErrorMessage';
import 'react-day-picker/lib/style.css';
import WeekSelector from '../../Components/WeekSelector/WeekSelector';

const Invoice = ({
    invoices: {
        status: invoiceStatus,
        invoices,
    },
    getInvoice,
}) => {

    const [currentDate] = useState(new Date());
    const [dateFrom, setStartSelectedDate] = useState(DateService.convertDate(DateService.getWeekStartDate(currentDate)));
    const [dateTo, setEndSelectedDate] = useState(DateService.convertDate(DateService.getWeekEndDate(currentDate)));

    const currentWeek = DateService.getCurrentWeekNumber(DateService.getCurrentDate());

    const [selectedWeek, setSelectedWeek] = useState(currentWeek);

    useEffect(() => {
        setTimeout(() => {
            getInvoice(dateFrom, dateTo);
        }, 0);
    }, [dateFrom, dateTo, getInvoice]);

    const [state, setState] = useState({
        hoverRange: undefined,
        selectedDays: [],
    });

    const updateWeekSelection = useCallback((weekNumber, days) => {
        setSelectedWeek(weekNumber);
        setStartSelectedDate(DateService.convertDate(days[0]));
        setEndSelectedDate(DateService.convertDate(days[6]));
        setState({
          selectedDays: days,
        });
    }, []);

    const { hoverRange, selectedDays } = state;

    const daysAreSelected = selectedDays.length > 0;

    const modifiers = {
        hoverRange,
        selectedRange: daysAreSelected && {
            from: selectedDays[0],
            to: selectedDays[6],
        },
        hoverRangeStart: hoverRange && hoverRange.from,
        hoverRangeEnd: hoverRange && hoverRange.to,
        selectedRangeStart: daysAreSelected && selectedDays[0],
        selectedRangeEnd: daysAreSelected && selectedDays[6],
    };

    return (
        <>
            <TopTitle title={`${useLocalized('nav_invoice')}`} subtitle={`${useLocalized('invoice_subtitle')}`} />
            <Grid container spacing={3}>
                <div className="week-select-section">
                    <Grid item xs={12} className="start-end-date-send-button">
                        <WeekSelector
                            selectedWeek={selectedWeek}
                            selectedDays={selectedDays}
                            dateFrom={dateFrom}
                            dateTo={dateTo}
                            modifiers={modifiers}
                            updateWeekSelection={updateWeekSelection}
                        />
                    </Grid>
                </div>
            </Grid>
            {(invoiceStatus === SUCCESS || invoices.length > 0) && <InvoiceSummary
                invoices={invoices}
                dateFrom={dateFrom}
                dateTo={dateTo}
                weekNumber={selectedWeek}
            />}
            {invoiceStatus === LOADING && invoices.length === 0 && <div className="loading-icon"><CircularProgress /></div>}
            {invoiceStatus === FAIL && <div className="no-data-error-text"><ErrorIcon /><ErrorMessage /></div>}
        </>
    );
};

Invoice.propTypes = {
    invoices: PropTypes.object,
    getInvoice: PropTypes.func,
    setStateToDefault: PropTypes.func,
};

export default connect(({
    invoices,
}) => ({
    invoices,
}), {
    getInvoice,
})(Invoice);
