import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { InputLabel, Popover } from '@material-ui/core';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import DateService from '../../Services/DateService';
import useLocalized from '../../Data/Localization';

const WeekSelector = ({
  selectedWeek,
  dateFrom,
  dateTo,
  selectedDays,
  modifiers,
  updateWeekSelection,
}) => {
    const labelWeekSelect = useLocalized('week_select_label');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'week-select-popover' : undefined;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleWeekClick = (weekNumber, days, e) => {
        updateWeekSelection(weekNumber, days);
        setAnchorEl(null);
    };

    return (
        <div>
            <InputLabel className="simple-select-label">{labelWeekSelect}</InputLabel>
            <div className="select-week-input" aria-describedby={id} onClick={handleClick}>
                <span className='week-count'>{`W${selectedWeek}`}</span>
                {DateService.getSplitDate(dateFrom)['year']}
                &nbsp;-&nbsp;
                {DateService.getSplitDate(dateTo)['year']}
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <DayPicker
                    selectedDays={selectedDays}
                    showWeekNumbers
                    showOutsideDays
                    modifiers={modifiers}
                    onWeekClick={handleWeekClick}
                    firstDayOfWeek={1}
                />
            </Popover>
        </div>
    );
};

WeekSelector.propTypes = {
    selectedWeek: PropTypes.number.isRequired,
    dateFrom: PropTypes.string.isRequired,
    dateTo: PropTypes.string.isRequired,
    selectedDays: PropTypes.array.isRequired,
    modifiers: PropTypes.object.isRequired,
    updateWeekSelection: PropTypes.func.isRequired
};

export default WeekSelector;
