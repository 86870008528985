import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import useLocalized from "../../Data/Localization";
import { AppBar, makeStyles, Tab, Tabs } from "@material-ui/core";
import TabPanel from "../TabPanel/TabPanel";
import PartnerListTable from "../PartnerListTable/PartnerListTable";

const PartnerList = ({
    presentedPartners,
    handlePartnerDataChange,
    handlePartnerActiveState,
}) => {

    // region States

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [activeIndex, setActiveIndex] = useState(0);
    const [activePartners, setActivePartners] = useState([]);
    const [inactivePartners, setInactivePartners] = useState([]);

    // endregion

    // region Style functions

    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: theme.palette.background.paper,
            width: 350,
        },
    }));

    const classes = useStyles();

    const a11yProps = useCallback((index) => {
        return {
            id: `tab-${index}`,
            "aria-controls": `tabpanel-${index}`,
        };
    }, []);

    // endregion

    // region Functions

    const sortPartners = useCallback((partners) => {
        return partners.sort((a, b) => {
            const companyComparison = a.companyName.localeCompare(b.companyName);
            if (companyComparison !== 0) {
                return companyComparison;
            }
            return a.location.localeCompare(b.location);
        });
    }, []);

    const separateActiveAndInactivePartners = useCallback((partners) => {
        const tempActivePartners = [];
        const tempInactivePartners = [];

        partners.forEach(partner => {
            if (partner.isActive) {
                tempActivePartners.push(partner);
            } else {
                tempInactivePartners.push(partner);
            }
        });

        const sortedActivePartners = sortPartners(tempActivePartners);
        const sortedInactivePartners = sortPartners(tempInactivePartners);

        setActivePartners(sortedActivePartners);
        setInactivePartners(sortedInactivePartners);
    }, [sortPartners]);

    // endregion

    // region Handler functions

    const handleChangePage = useCallback((event, newPage) => {
        setPage(newPage);
    }, []);

    const handleChangeRowsPerPage = useCallback((event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    }, []);

    const handleTabChange = useCallback((event, newIndex) => {
        setActiveIndex(newIndex);
    }, []);

    // endregion

    // region UseEffect calls

    useEffect(() => {
        separateActiveAndInactivePartners(presentedPartners);
    }, [presentedPartners, separateActiveAndInactivePartners]);

    // endregion

    // region UI Render

    return (
        <>
            <AppBar className={classes.root} position='static' color='default' elevation={1}>
                <Tabs
                    value={activeIndex}
                    variant='fullWidth'
                    onChange={handleTabChange}
                >
                    <Tab label={`${useLocalized("partners_active_title")}`} {...a11yProps(0)} />
                    <Tab label={`${useLocalized("partners_inactive_title")}`} {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <TabPanel value={activeIndex} index={0}>
                <PartnerListTable
                    partners={activePartners}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handlePartnerDataChange={handlePartnerDataChange}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    handlePartnerActiveState={handlePartnerActiveState}
                />
            </TabPanel>
            <TabPanel value={activeIndex} index={1}>
                <PartnerListTable
                    partners={inactivePartners}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handlePartnerDataChange={handlePartnerDataChange}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    handlePartnerActiveState={handlePartnerActiveState}
                />
            </TabPanel>
        </>
    );

    // endregion
};

/* MARK: - Prop types */
PartnerList.propTypes = {
    partners: PropTypes.array,
    handlePartnerDataChange: PropTypes.func,
};

export default PartnerList;
