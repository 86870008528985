import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: '#029EFF'
    }
  },
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: 'none',
      },
      root: {
        backgroundColor: '#F5F7FA',
        color: '#707070',
      }
    },
    MuiTableCell: {
      root: {
        borderBottom: 0,
        color: '#707070',
        padding: 0,
        fontSize: 16
      },
      body: {
        color: '#707070',
      },
      sizeSmall: {
        padding: '2px 0 0 0',
        margin: 4,
      },
      head: {
        lineHeight: '1.2',
        fontSize: 12,
        color: '#707070',
        textTransform: 'uppercase',
        fontWeight: 700
      }
    },
    MuiFormControl: {
      fullWidth: {
        backgroundColor: 'white',
        boxShadow: '0px 0px 6px 4px rgba(0,0,0,0.04)',
        border: "none",
      },
      marginNormal: {
        width: '100%',
        marginBottom: 0
      }
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: 'white'
      }
    },
    MuiAutocomplete: {
      inputRoot: {
        height: 50,
        paddingLeft: 30,
        "&:hover": {
          boxShadow: '0px 0px 10px 6px rgba(0,0,0,0.04)',
        }
      },
    },
    MuiInputBase: {
      root: {
        color: '#707070',
        letterSpacing: 0,
        fontSize: 14,
      },
      input: {
        height: 18,
        backgroundColor: '#ffffff',
      }
    },
    MuiSelect: {
      icon: {
        top: 'calc(51% - 12px)',
        right: 8,
      },
      select: {
        padding: '14px 12px 0px 5px'
      },
      selectMenu: {
        minHeight: '1.85em',
        paddingBottom: 2,
        fontSize: 14
      }
    },
    MuiTablePagination: {
      selectIcon: {
        top: 'calc(51% - 14px)',
        right: 3,
      }
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: '#029EFF'
      }
    },
    MuiIconButton: {
      root: {
        padding: '12px 12px 16px 12px'
      }
    },
    MuiFormLabel: {
      root: {
        fontWeight: 700
      }
    },
    MuiInput: {
      underline: {
        "&::before": {
          borderBottom: 0
        }
      }
    },
    MuiButton: {
      root: {
        backgroundColor: '#029EFF',
        borderRadius: 0,
        color: '#ffffff',
        boxShadow: '0px 0px 6px 4px rgba(0,0,0,0.04)',
        fontSize: 12,
        opacity: 0.9,
        "&:hover": {
          backgroundColor: '#029EFF',
          opacity: 1,
        },
        "&:disabled": {
          backgroundColor: '#B8B8B8'
        }
      }
    },
    MuiTab: {
      root: {
        color: '#14C8E0',
        '&$selected': {
          color: '#14C8E0',
        },
      }
    },
    MuiTabs: {
      indicator: {
        backgroundColor: '#14C8E0',
      },
    },
    MuiFormHelperText: {
      root: {
        color: 'red',
      }
    },
    MuiAccordion: {
      root: {
        "&:before": {
          backgroundColor: 'none'
        },
        "&$expanded": {
          margin: 0
        }
      }
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: '-15px',
        marginRight: 0,
      }
    },
    MuiAccordionSummary: {
      root: {
        "&$expanded": {
          minHeight: 0
        }
      },
      content: {
        margin: 0,
        "&$expanded": {
          margin: 0
        }
      }
    },
    MuiListItem: {
      gutters: {
        paddingLeft: 0,
        paddingRight: 0
      },
      root: {
        paddingTop: 0,
        paddingBottom: 0,
        lineHeight: 1.2,
        justifyContent: 'center'
      }
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      }
    },
  }
});

export default theme;
