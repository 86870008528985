import React, { Fragment, useCallback, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import DateService from "../../Services/DateService";
import DriversTimesheetCalendar from "../../Components/DriversTimesheetCalendar/DriversTimesheetCalendar";
import PriceService from "../../Services/PriceService";
import TimesheetNote from "../../Components/TimesheetNote/TimesheetNote";
import useScrollSync from "../../CustomHooks/useScrollSync";
import useLocalizedArray from "../../CustomHooks/useLocalizedArray";
import PropTypes from "prop-types";

// region Constants

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1
    },
    sccont: {
        marginTop: 80,
    },
}));

const VEHICLE_TYPES = [
    { key: "van", label: "vehicleTypeVan" },
    { key: "van_payroll", label: "vehicleTypeVanPayroll" },
    { key: "hiab", label: "vehicleTypeHiab" },
    { key: "hiab_payroll", label: "vehicleTypeHiabPayroll" },
    { key: "seven_tons", label: "vehicleTypeSevenTons" },
    { key: "loft_l", label: "vehicleTypeLoftL" },
    { key: "special", label: "vehicleTypeSpecial" },
    { key: "moffett", label: "vehicleTypeMoffet" },
    { key: "adr", label: "vehicleTypeAdr" }
];

const TRANSLATION_KEYS = [
    "drivers_table_head_cell_nr",
    "drivers_table_head_cell_driver_name",
    "vehicle_type_van",
    "vehicle_type_hiab",
    "vehicle_type_adr",
    "vehicle_type_moffet",
    "vehicle_type_special",
    "vehicle_type_van_payroll",
    "vehicle_type_hiab_payroll",
    "vehicle_type_loft_l",
    "vehicle_type_seven_tons",
    "driver_jobs_header_hours_label",
    "driver_jobs_header_overtime_label",
    "driver_total_hours_label",
    "driver_payment_label",
    "driver_total_payment_label",
    "driver_timesheet_note",
    "company_profit_label",
    "nav_invoice",
    "company_total_label",
    "vehicle_type_filter_alert_message"
];

const DAYS = ["M", "Tu", "W", "Th", "Fr", "Sa", "Su"];

// endregion

/**
 * DriversTimesheet Component
 *
 * Displays a timesheet for drivers based on the provided date range and week number.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.timesheets - List of timesheets for the drivers.
 * @param {string} props.dateFrom - Start date of the timesheet period (ISO format or relevant date string).
 * @param {string} props.dateTo - End date of the timesheet period (ISO format or relevant date string).
 * @param {number} props.weekNumber - The week number for which the timesheet is displayed.
 * @param {Function} props.getTimesheetByDriver - Function to retrieve timesheet data for a specific driver.
 *
 * @returns {JSX.Element} The rendered DriversTimesheet component.
 */
const DriversTimesheet = ({
    timesheets,
    dateFrom,
    dateTo,
    weekNumber,
    getTimesheetByDriver,
}) => {

    // region UseRef hooks

    const headerItemsRef = useRef([]);
    const weekItemsRef = useRef([]);

    // endregion

    // region Custom hooks

    const translations = useLocalizedArray(TRANSLATION_KEYS);
    const classes = useStyles();
    useScrollSync("syncscroll");

    // endregion

    // region Function

    /**
     * Synchronizes the widths of header items with their corresponding table columns.
     * Ensures the header and table remain visually aligned.
     */
    const syncHeaderAndTableWidths = useCallback(() => {
        if (headerItemsRef.current && weekItemsRef.current) {
            headerItemsRef.current.forEach((headerItem, index) => {
                const weekItem = weekItemsRef.current[index];
                if (headerItem && weekItem) {
                    const width = weekItem.offsetWidth;
                    headerItem.style.width = `${width}px`;
                }
            });
        }
    }, []);

    // endregion

    // region UI Builder functions

    /**
     * Generates a timesheet calendar for a given driver's job data.
     *
     * @param {Array} driverJob - List of timesheet entries for the driver.
     * @param {number} driverId - ID of the driver.
     * @param {boolean} hasHourlyRates - Flag indicating if hourly rates should be displayed.
     * @returns {JSX.Element[]} Array of JSX elements representing the timesheet for the week.
     */
    const renderTimesheetWeekDays = useCallback((driverJob, driverId, hasHourlyRates) => {
        const timesheet = [];
        let date = new Date(dateFrom);

        for (let day = 0; day < 7; day++) {
            let newDate = DateService.convertDate(date);
            timesheet.push(
                <DriversTimesheetCalendar
                    day={DAYS[day]}
                    date={newDate}
                    hasHourlyRates={hasHourlyRates}
                />
            );
        }

        driverJob.forEach((drivertimesheet) => {
            const itemDate = new Date(drivertimesheet.date);
            const dayNumber = DateService.getWeekDay(itemDate);
            timesheet[dayNumber] = (
                <DriversTimesheetCalendar
                    idjob={drivertimesheet.id}
                    day={DAYS[dayNumber]}
                    date={drivertimesheet.date}
                    driverId={driverId}
                    companyName={drivertimesheet.order_company}
                    companyLocation={drivertimesheet.order_location}
                    vehicleType={drivertimesheet.vehicle_type}
                    hours={drivertimesheet.hours}
                    overtimeHours={drivertimesheet.overtime_hours}
                    adminModifiedHours={drivertimesheet.admin_modified_hours}
                    adminModifiedOvertimeHours={drivertimesheet.admin_modified_overtime_hours}
                    getTimesheetByDriver={getTimesheetByDriver}
                    vehicleTypeAlertMessage={translations.vehicleTypeFilterAlertMessage}
                    hasHourlyRates={hasHourlyRates}
                />
            );
        });

        return timesheet;
    }, [dateFrom, getTimesheetByDriver, translations.vehicleTypeFilterAlertMessage]);

    /**
     * Renders a header component displaying vehicle type columns based on driver details.
     * Uses useCallback to memoize the function for performance optimization.
     *
     * @param {Object} driverDetails - Object containing driver information and hourly rate flags
     * @param {boolean} [driverDetails.has_key_hourly_rate] - Flags indicating if driver has hourly rate for specific vehicle type
     * @returns {JSX.Element} A div element containing vehicle type header columns
     */
    const renderVehicleTypeHeader = useCallback((driverDetails) => (
        <div className="timesheet-header-info">
            <div className="empty-header-col"></div>
            {VEHICLE_TYPES.map(({ key, label }) => (
                driverDetails[`has_${key}_hourly_rate`] && (
                    <div key={key} className="vehicle-type-col-ext">
                        {translations[label]}
                    </div>
                )
            ))}
        </div>
    ), [translations]);

    /**
     * Renders the header section for a timesheet displaying hours information.
     * Uses useCallback to memoize the function for performance optimization.
     *
     * @param {Object} driverDetails - Details about the driver including hourly rate flags
     * @param {boolean} driverDetails.has_[vehicleType]_hourly_rate - Flags indicating if driver has hourly rate for specific vehicle type
     * @returns {JSX.Element} A React component containing the timesheet header structure
     */
    const renderHoursHeader = useCallback((driverDetails) => (
        <div className="timesheet-header-info">
            <div className="empty-header-col"></div>
            {VEHICLE_TYPES.map(({ key }) => (
                driverDetails[`has_${key}_hourly_rate`] && (
                    <Fragment key={key}>
                        <div className="hours-quantity-col-ext">{translations.driverJobsHeaderHoursLabel}</div>
                        <div className="hours-quantity-col-ext">{translations.driverJobsHeaderOvertimeLabel}</div>
                    </Fragment>
                )
            ))}
        </div>
    ), [translations.driverJobsHeaderHoursLabel, translations.driverJobsHeaderOvertimeLabel]);

    /**
     * Renders payment details for drivers based on provided data.
     *
     * @param {Object} driverDetails - Details of the driver.
     * @param {Object} paymentDetails - Payment details for the driver.
     * @param {boolean} [isPaymentLine=false] - Flag indicating if it's a payment line.
     * @returns {JSX.Element} Rendered payment details component.
     */
    const renderPaymentDetails = useCallback((driverDetails, paymentDetails, isPaymentLine = false ) => (
        <div className="timesheet-header-info driver-payment-details">
            <div className="week-empty-col"></div>
            <div className={`empty-header-payment-col${isPaymentLine ? " driver-payment-details-line" : ""}`}>
                {translations[isPaymentLine ? "driverPaymentLabel" : "driverTotalHoursLabel"]}
            </div>
            {VEHICLE_TYPES.map(({ key }) => (
                driverDetails[`has_${key}_hourly_rate`] && (
                    <Fragment key={key}>
                        <div className={`hours-quantity-col${isPaymentLine ? " driver-payment-details-line" : ""}`}>
                            {paymentDetails[`${isPaymentLine ? "payment" : "total_hours"}_${key}`] !== 0.0
                                ? PriceService.getFormattedPrice(paymentDetails[`${isPaymentLine ? "payment" : "total_hours"}_${key}`])
                                : 0}
                        </div>
                        <div className={`hours-quantity-col${isPaymentLine ? " driver-payment-details-line" : ""}`}>
                            {paymentDetails[`${isPaymentLine ? "payment" : "total"}_overtime_${key}`] !== 0.0
                                ? PriceService.getFormattedPrice(paymentDetails[`${isPaymentLine ? "payment" : "total"}_overtime_${key}`])
                                : 0}
                        </div>
                    </Fragment>
                )
            ))}
        </div>
    ), [translations]);

    /**
     * Renders the company payment section, displaying total, payment, and profit details for a company.
     *
     * @param {Object} driverDetails - Details of the driver.
     * @param {Object} companyPayment - Payment details for the company, including total, payment, and profit data.
     * @returns {JSX.Element} Rendered company payment section component.
     */
    const renderCompanyPaymentSection = useCallback((driverDetails, companyPayment) => (
        <div className="company-payment-list">
            {["total", "payment", "profit"].map(type => (
                <div key={type} className="timesheet-header-info driver-payment-details">
                    <div className="week-empty-col"></div>
                    <div className="empty-header-payment-col">
                        {companyPayment.company_name} {translations[type === "total" ? "companyTotalLabel" : type === "payment" ? "navInvoice" : "companyProfitLabel"]}
                    </div>
                    {VEHICLE_TYPES.map(({ key }) => (
                        driverDetails[`has_${key}_hourly_rate`] && (
                            <Fragment key={key}>
                                <div className="hours-quantity-col">
                                    {companyPayment[`${type}_hours_${key}`] !== 0.0
                                        ?  PriceService.getFormattedPrice(companyPayment[type === "payment" ? `${type}_${key}` : `${type}_hours_${key}`])
                                        : 0}
                                </div>
                                <div className="hours-quantity-col">
                                    {companyPayment[`${type}_overtime_${key}`] !== 0.0
                                        ? PriceService.getFormattedPrice(companyPayment[`${type}_overtime_${key}`])
                                        : 0}
                                </div>
                            </Fragment>
                        )
                    ))}
                </div>
            ))}
        </div>
    ), [translations]);

    /**
     * Renders a timesheet row for a driver, including job details, payment details, and notes.
     *
     * @param {Object} value - Object containing driver details, jobs, payment details, and timesheet notes.
     * @param {number} nrdriver - Index of the driver in the list.
     * @param {number} weekNumber - The week number being displayed.
     * @param {string} dateFrom - The start date of the timesheet period.
     * @param {string} dateTo - The end date of the timesheet period.
     * @returns {JSX.Element} Rendered timesheet row component.
     */
    const renderTimesheetRow = useCallback(( value, nrdriver, weekNumber, dateFrom, dateTo ) => (
        <Grid
            className="driver-timesheet-list"
            xs={false}
            key={nrdriver}
            item
            ref={(el) => (weekItemsRef.current[nrdriver] = el)}
        >
            <Paper>
                <div className="week-row-to-schedule">
                    { renderVehicleTypeHeader(value.driver_details) }
                    { renderHoursHeader(value.driver_details) }

                    <div className="week-nr-col">
                        <div className="note-date">{nrdriver === 0 ? `W${weekNumber}` : " "}</div>
                    </div>

                    <div className="driver-week-list">
                        { renderTimesheetWeekDays(value.driver_jobs, value.driver_details.driver_id, value.driver_details) }
                    </div>

                    { renderPaymentDetails(value.driver_details, value.driver_payment_details) }

                    { renderPaymentDetails(value.driver_details, value.driver_payment_details, true) }

                    <div className="timesheet-header-info driver-payment-details">
                        <div className="week-empty-col"></div>
                        <div className="empty-header-col-details">{translations.driverTotalPaymentLabel}</div>
                        <div className="driver-payment-details-total">
                            {value.driver_payment_details.payment_total !== 0.0
                                ? PriceService.getFormattedPrice(value.driver_payment_details.payment_total)
                                : 0}
                        </div>
                    </div>

                    <div className="timesheet-header-info driver-timesheet-note">
                        <div className="week-empty-col"></div>
                        <div className="empty-header-col-details driver-payment-details-line">{translations.driverTimesheetNote}</div>
                        <div className="driver-payment-details-total driver-payment-details-line">
                            <TimesheetNote
                                noteTimesheet={value.timesheet_note}
                                dateFrom={dateFrom}
                                dateTo={dateTo}
                                driverId={value.driver_details.driver_id}
                            />
                        </div>
                    </div>

                    {value.company_payment_list.map((cplist) => renderCompanyPaymentSection(value.driver_details, cplist))}

                    <div className="clr"></div>
                </div>
            </Paper>
        </Grid>
    ), [renderCompanyPaymentSection, renderHoursHeader, renderPaymentDetails, renderTimesheetWeekDays, renderVehicleTypeHeader, translations.driverTimesheetNote, translations.driverTotalPaymentLabel]);

    // endregion

    // region UseEffect calls

    /**
     * Synchronizes header and table column widths whenever the timesheets data changes.
     * This ensures that the table layout remains consistent.
     */
    useEffect(() => {
        syncHeaderAndTableWidths();
    }, [syncHeaderAndTableWidths]);

    // endregion

    return (
        <Grid container className={classes.root} spacing={2}>
            <Grid container className={classes.sccont} spacing={0}>

                <div className="timesheet-header-wrapper">
                    <div className="scroller syncscroll" name="scrollSyncedElement">
                        {timesheets.timesheetData.map((value, nrdriver) => (
                            <div
                                className="driver-schedule-header"
                                key={nrdriver}
                                ref={(el) => (headerItemsRef.current[nrdriver] = el)}
                            >
                                <label className="labelCustom">{translations.driversTableHeadCellNr}</label>
                                <div className="driver-info-section">
                                    <div className="driver-info">
                                        <div className="driver-color-row" style={{ backgroundColor: "#" + value.driver_details.driver_color_code }}></div>
                                        <div className="driver-nr">#{value.driver_details.driver_number}</div>
                                    </div>
                                    <div className="driver-location">{value.driver_details.driver_location}</div>
                                </div>
                                <div className="driver-name-row">
                                    <label className="labelCustom">{translations.driversTableHeadCellDriverName}</label>
                                    <div className="driver-name">{value.driver_details.driver_name}</div>
                                </div>
                            </div>
                        ))}
                        <Divider />
                    </div>
                </div>

                <div className="scrolling-wrapper-flexbox syncscroll" name="scrollSyncedElement">
                    {timesheets.timesheetData.map((value, nrdriver) => renderTimesheetRow(value, nrdriver, weekNumber, dateFrom, dateTo))}
                </div>
            </Grid>
        </Grid>
    );
};

DriversTimesheet.propTypes = {
    timesheets: PropTypes.arrayOf(PropTypes.object).isRequired,
    dateFrom: PropTypes.string.isRequired,
    dateTo: PropTypes.string.isRequired,
    weekNumber: PropTypes.number.isRequired,
    getTimesheetByDriver: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const {
        timesheets: { status },
    } = state;
    return { status };
};

export default connect(mapStateToProps, null)(DriversTimesheet);
