import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useErrorHandler, useWebService, useWebServiceWithId } from "../../CustomHooks";
import {
    changeDriverDetails,
    getDriverById,
    getDriverHistories,
    setDriverData,
    setDriverDataNote,
    setDriverHoliday
} from "../../Redux/Actions/DriverDataAction";
import { getLocationList } from "../../Redux/Actions/LocationAction";
import useLocalized from "../../Data/Localization";
import TopTitle from "../../Components/TopTitle/TopTitle";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Button from "@material-ui/core/Button";
import { CURRENCY_SYMBOL, FORMAT_DATE, gradeColors } from "../../Constants/options";
import DriverNotes from "../../Components/DriverNotes/DriverNotes";
import DriverHistoryList from "../../Components/DriverHistoryList/DriverHistoryList";
import { ColorPicker } from "material-ui-color";
import ErrorMessage from "../../Components/ErrorMessage/ErrorMessage";
import { makeStyles } from "@material-ui/core/styles";
import {
    DRIVER_HOLIDAY_FAILED,
    DRIVER_HOLIDAY_SUCCESS,
    DRIVER_UPDATE_FAILED,
    DRIVER_UPDATE_SUCCESS,
    FAIL,
    LOADING,
    NOTE_FAILED,
    SUCCESS
} from "../../Redux/ActionTypes";
import ErrorIcon from "@material-ui/icons/Error";
import CircularProgress from "@material-ui/core/CircularProgress";
import Input from "@material-ui/core/Input";
import DateService from "../../Services/DateService";
import EventNoteIcon from "@material-ui/icons/EventNote";
import FormHelperText from "@material-ui/core/FormHelperText";
import { KeyboardDatePicker } from "@material-ui/pickers";
import InputAdornment from "@material-ui/core/InputAdornment";
import useMuiTheme from "../../CustomHooks/useMuiTheme";

const DriverDetail = ({
    driverDetail: {
        status: driverDataStatus,
        errorCode,
        driver: {
            id,
            name,
            grade,
            gradeInitialValue,
            phone,
            phoneInitialValue,
            address,
            addressInitialValue,
            color_code,
            colorCodeInitialValue,
            number,
            numberInitialValue,
            notes,
            histories,
            hourly_rate_van,
            hourly_rate_hiab,
            hourly_rate_special,
            hourly_rate_loft_l,
            hourly_rate_seven_tons,
            hourly_rate_hiab_payroll,
            hourly_rate_van_payroll,
            hourly_rate_adr,
            hourly_rate_moffett,
            overtime_hourly_rate_moffett,
            overtime_hourly_rate_adr,
            overtime_hourly_rate_van_payroll,
            overtime_hourly_rate_hiab_payroll,
            overtime_hourly_rate_seven_tons,
            overtime_hourly_rate_loft_l,
            overtime_hourly_rate_van,
            overtime_hourly_rate_hiab,
            overtime_hourly_rate_special,
            has_special_hourly_rate,
            has_van_hourly_rate,
            has_hiab_hourly_rate,
            has_van_payroll_hourly_rate,
            has_hiab_payroll_hourly_rate,
            has_loft_l_hourly_rate,
            has_seven_tons_hourly_rate,
            has_moffett_hourly_rate,
            has_adr_hourly_rate,
        },
    },
    location: {
        locationStatus,
        locationData,
    },
    changeDriverDetails,
    setDriverData,
    getDriverHistories,
    getDriverById,
    setDriverDataNote,
    setDriverHoliday,
    getLocationList,
    match: {
        params: {
            driverId,
        },
    },
}) => {

    useWebServiceWithId(driverDataStatus, getDriverById, driverId);
    useWebService(locationStatus, getLocationList);

    const labelDriverName = useLocalized("drivers_table_head_cell_driver_name");
    const labelLocation = useLocalized("drivers_table_head_cell_location");
    const labelDriverColor = useLocalized("driver_color");
    const labelDriverText = useLocalized("driver_text");
    const labelDriverGrade = useLocalized("drivers_table_head_cell_grade");
    const gradeBeginnerText = useLocalized("grade_color_beginner_text");
    const gradeBadText = useLocalized("grade_color_bad_text");
    const gradeProText = useLocalized("grade_color_pro_text");
    const labelDayOff = useLocalized("day_off_label");
    const labelDayOffStart = useLocalized("day_off_start_label");
    const labelDayOffEnd = useLocalized("day_off_end_label");
    const labelNotesText = useLocalized("driver_notes_label");
    const labelPlaceholderNotesText = useLocalized("notes_placeholder_text");
    const historyTitleText = useLocalized("history_label_text");
    const updateButton = useLocalized("update_button");
    const addHolidayButton = useLocalized("add_button_text");
    const labelDriverPhone = useLocalized("drivers_table_head_cell_phone");
    const labelNoHistory = useLocalized("no_history");
    const labelNoDriverNote = useLocalized("no_data_driver_note");
    const driverProfileSuccessUpdateText = useLocalized("driver_profile_successfully_updated");
    const noDriverNoteAddedText = useLocalized("no_driver_note_added_text");
    const driverHolidaySaved = useLocalized("driver_holiday_saved_successfully");
    const hourlyRateTitle = useLocalized("hourly_rate_title");
    const overtimeRateTitle = useLocalized("overtime_rate_title");
    const vehicleTitle = useLocalized("vehicle_title");
    const vehicleTypeVan = useLocalized("vehicle_type_van");
    const vehicleTypeVanPayroll = useLocalized("vehicle_type_van_payroll");
    const vehicleTypeHiab = useLocalized("vehicle_type_hiab");
    const vehicleTypeHiabPayroll = useLocalized("vehicle_type_hiab_payroll");
    const vehicleTypeLoftL = useLocalized("vehicle_type_loft_l");
    const vehicleTypeSevenTons = useLocalized("vehicle_type_seven_tons");
    const vehicleTypeSpecial = useLocalized("vehicle_type_special");
    const labelDatePicker = useLocalized("date_picker_aria_label");
    const addPlaceHolder = useLocalized("add_button_text");
    const vehicleTypeMoffet = useLocalized("vehicle_type_moffet");
    const vehicleTypeAdr = useLocalized("vehicle_type_adr");

    const useStyles = makeStyles({
        root: {
            "&$disabled": {
                color: "white",
                backgroundColor: "rgb(219, 219, 219)"
            },
        },
        disabled: {},
        inputRoot: {
            backgroundColor: "#fff",
            "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: "0px",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
                borderWidth: "0px",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderWidth: "0px",
            }
        }
    });

    const [disabledUpdateButton, setDisableUpdateButton] = useState(true);
    const [showSuccessDataMessage, setSuccessMessage] = useState(driverProfileSuccessUpdateText);
    const [showSuccessDataHolidayMessage, setSuccessHolidayMessage] = useState(driverHolidaySaved);
    const [allValues, setAllValues] = useState();
    const [editedNumber, setNumber] = useState(number);
    const [editedPhone, setPhone] = useState(phone);
    const [editedAddress, setAddress] = useState(address);
    const [editedColorCode, setColorCode] = useState(color_code);
    const [editedGrade, setGrade] = useState(grade);

    useEffect(() => {
        setNumber(number);
        setPhone(phone);
        setAddress(address);
        setColorCode(color_code);
        setGrade(grade);
        setAllValues({
            hourly_rate_van,
            hourly_rate_hiab,
            hourly_rate_special,
            hourly_rate_loft_l,
            hourly_rate_seven_tons,
            hourly_rate_hiab_payroll,
            hourly_rate_van_payroll,
            hourly_rate_adr,
            hourly_rate_moffett,
            overtime_hourly_rate_moffett,
            overtime_hourly_rate_adr,
            overtime_hourly_rate_van_payroll,
            overtime_hourly_rate_hiab_payroll,
            overtime_hourly_rate_seven_tons,
            overtime_hourly_rate_loft_l,
            overtime_hourly_rate_van,
            overtime_hourly_rate_hiab,
            overtime_hourly_rate_special,
            has_special_hourly_rate,
            has_van_hourly_rate,
            has_hiab_hourly_rate,
            has_van_payroll_hourly_rate,
            has_hiab_payroll_hourly_rate,
            has_loft_l_hourly_rate,
            has_seven_tons_hourly_rate,
            has_moffett_hourly_rate,
            has_adr_hourly_rate,
        });
    }, [
        number,
        phone,
        address,
        color_code,
        grade,
        hourly_rate_van,
        overtime_hourly_rate_van,
        hourly_rate_hiab,
        overtime_hourly_rate_hiab,
        hourly_rate_special,
        overtime_hourly_rate_special,
        has_special_hourly_rate,
        has_van_hourly_rate,
        has_hiab_hourly_rate,
        has_van_payroll_hourly_rate,
        has_hiab_payroll_hourly_rate,
        has_loft_l_hourly_rate,
        has_seven_tons_hourly_rate,
        hourly_rate_van_payroll,
        overtime_hourly_rate_van_payroll,
        hourly_rate_hiab_payroll,
        overtime_hourly_rate_hiab_payroll,
        hourly_rate_seven_tons,
        overtime_hourly_rate_seven_tons,
        hourly_rate_loft_l,
        overtime_hourly_rate_loft_l,
        has_adr_hourly_rate,
        has_moffett_hourly_rate,
        hourly_rate_adr,
        hourly_rate_moffett,
        overtime_hourly_rate_moffett,
        overtime_hourly_rate_adr,
    ]);

    const changeDriverData = useCallback(({ name, value }) => {
        setAllValues({ ...allValues, [name]: value });
        setDisableUpdateButton(false);
    }, [allValues]);

    const handleSaveDriverDataSuccess = useCallback(() => {
        getDriverHistories(driverId);
    }, [driverId, getDriverHistories]);

    const saveDriverData = useCallback(() => {
        setDriverData(driverId, allValues, handleSaveDriverDataSuccess);
        setDisableUpdateButton(true);
        setTimeout(() => setSuccessMessage(), 5000);
        setSuccessMessage(driverProfileSuccessUpdateText);
    }, [allValues, setDriverData, setDisableUpdateButton, handleSaveDriverDataSuccess, driverProfileSuccessUpdateText, driverId]);

    const [notelist, setNote] = useState("");

    const [driverNoteErrorText, setDriverNoteErrorText] = useState("");

    const [disabledNote, setDisableNoteButton] = useState(true);

    const handleAddNote = (event) => {
        setNote(event.target.value);
        setDisableNoteButton(event.target.value === null);
    };

    const saveDriverNote = useCallback(() => {
        if (!notelist.trim()) {
            setDriverNoteErrorText(noDriverNoteAddedText);
            return;
        } else {
            setDriverNoteErrorText("");
            setNote("");
            setDisableNoteButton(true);
        }
        setDriverDataNote(
            driverId,
            notelist,
        );
    }, [
        driverId,
        notelist,
        setDriverDataNote,
        noDriverNoteAddedText,
    ]
    );


    const [date_from, setStartSelectedDate] = useState(DateService.getCurrentDate());
    const [date_to, setEndSelectedDate] = useState(DateService.getCurrentDate());

    const saveHolidayData = useCallback(() => {

        setDriverHoliday(driverId, date_from, date_to);

        setTimeout(() => setSuccessHolidayMessage(), 5000);
        setSuccessHolidayMessage(driverHolidaySaved);

    }, [driverId, date_from, date_to, setDriverHoliday, driverHolidaySaved]);

    const errorMessage = useErrorHandler(driverDataStatus, errorCode, false);

    const classes = useStyles();

    const styles = useMuiTheme();

    const cellTexField = useCallback(({ name, value, checked }) => {
        return <TextField
            style={{ width: 120, backgroundColor: "white" }}
            type="number"
            placeholder={addPlaceHolder}
            variant='outlined'
            value={value}
            name={name}
            className={styles.root}
            disabled={!checked}
            onChange={(e) => changeDriverData({ name: e.target.name, value: e.target.value })}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        {CURRENCY_SYMBOL}
                    </InputAdornment>
                ),
            }} />;
    }, [styles.root, addPlaceHolder, changeDriverData]);

    const cellCheckBox = useCallback(({ name, value, label }) => {
        return <FormControlLabel control={
            <Checkbox
                checked={value ?? false}
                size="medium"
                className='checkBox'
                name={name}
                color='primary'
                onChange={(e) => changeDriverData({ name: e.target.name, value: e.target.checked })}
            />
        } label={
            <div className='checkbox-title'>{label}</div>
        } />;
    }, [changeDriverData]);

    return (
        <>
            <TopTitle title={`${useLocalized("driver_profile_title")}`} subtitle={`${useLocalized("driver_profile_subtitle")}`} />
            {driverDataStatus === LOADING && <div className="loading-icon"><CircularProgress /></div>}
            {driverDataStatus === FAIL && <div className="no-data-error-text"><ErrorIcon /><ErrorMessage /></div>}
            {(driverDataStatus !== SUCCESS || driverDataStatus !== FAIL) && <div>
                <Grid container>
                    <Grid className="detail-left-grid" item xs={5}>
                        <Grid container>
                            <Grid item xs={4} className="grid-to-inputs">
                                <div className="driver-name">
                                    <label className="input-label">{labelDriverName}</label>
                                    <div className="input-name">{name}</div>
                                </div>
                                <label className="labelCustom">{labelLocation}</label>
                                <Autocomplete
                                    onChange={(e, newInputValue) => { setAddress(newInputValue.name); changeDriverData({ name: "address", value: newInputValue.name });}}
                                    name='address'
                                    value={locationData.find(option => option.name === editedAddress) || ""}
                                    id="location-box"
                                    className="general-width-to-inputs"
                                    options={locationData}
                                    classes={classes}
                                    getOptionLabel={(option) => (option ? option.name : "")}
                                    style={{ marginTop: 8 }}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" label="" placeholder={address} />
                                    )}
                                />
                                <div className="input-empty-space">
                                    <FormControl className="general-width-to-inputs">
                                        <InputLabel className="simple-select-label general-width-to-inputs">{labelDriverText} {labelDriverGrade}</InputLabel>
                                        <Select
                                            id="simple-select"
                                            value={editedGrade}
                                            displayEmpty
                                            onChange={(e) => { setGrade(e.target.value); changeDriverData({ name: e.target.name, value: e.target.value });}}
                                            name='grade'
                                        >
                                            <MenuItem value={gradeBeginnerText}><span className="grade-circle" style={{ backgroundColor: gradeColors["BEGINNER"] }}></span>{gradeBeginnerText}</MenuItem>
                                            <MenuItem value={gradeBadText}><span className="grade-circle" style={{ backgroundColor: gradeColors["MIDDLE"] }}></span>{gradeBadText}</MenuItem>
                                            <MenuItem value={gradeProText}><span className="grade-circle" style={{ backgroundColor: gradeColors["PRO"] }}></span>{gradeProText}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className="driver-color-picker">
                                    <label className="labelCustom">{labelDriverColor}</label>
                                    <div className="color-picker-row">
                                        <ColorPicker
                                            name="color_code"
                                            style={{ marginTop: 15 }}
                                            onChange={(e) => { setColorCode(e.hex);  changeDriverData({ name: "color_code", value: e.hex });}}
                                            value={`#${editedColorCode}`}
                                            defaultValue={`#${editedColorCode}`}
                                            hideTextfield
                                            deferred />
                                        <Input
                                            id="standard-name"
                                            className="driver-nr"
                                            name='number'
                                            onChange={(e) => { setNumber(e.target.value); changeDriverData({ name: e.target.name, value: e.target.value });}}
                                            value={editedNumber ?? ""}
                                        />
                                    </div>
                                </div>
                                <label className="labelCustom">{labelDriverPhone}</label>
                                <TextField id="standard-name" name='phone' className="lstyletoinputs general-width-to-inputs" onChange={(e) => { setPhone(e.target.value); changeDriverData({ name: e.target.name, value: e.target.value });}} value={editedPhone} />
                            </Grid>
                            <div className='hourly-rate-container'>
                                <div className="table">
                                    <div className="table-row">
                                        <div className="table-head-vehicle">{vehicleTitle}</div>
                                        <div className="table-cell-textfield-left-title">{hourlyRateTitle}</div>
                                        <div className="table-cell-textfield-right-title">{overtimeRateTitle}</div>
                                    </div>
                                    <div className="table-row">
                                        <div className="table-cell-vehicle">{cellCheckBox({ name: "has_van_hourly_rate", value: allValues?.has_van_hourly_rate, label: vehicleTypeVan })}</div>
                                        <div className="table-cell-textfield-left">{cellTexField({ name: "hourly_rate_van", value: allValues?.hourly_rate_van, checked: allValues?.has_van_hourly_rate })}</div>
                                        <div className="table-cell-textfield-right">{cellTexField({ name: "overtime_hourly_rate_van", value: allValues?.overtime_hourly_rate_van, checked: allValues?.has_van_hourly_rate })}</div>
                                    </div>
                                    <div className="table-row">
                                        <div className="table-cell-vehicle">{cellCheckBox({ name: "has_van_payroll_hourly_rate", value: allValues?.has_van_payroll_hourly_rate, label: vehicleTypeVanPayroll })}</div>
                                        <div className="table-cell-textfield-left">{cellTexField({ name: "hourly_rate_van_payroll", value: allValues?.hourly_rate_van_payroll, checked: allValues?.has_van_payroll_hourly_rate })}</div>
                                        <div className="table-cell-textfield-right">{cellTexField({ name: "overtime_hourly_rate_van_payroll", value: allValues?.overtime_hourly_rate_van_payroll, checked: allValues?.has_van_payroll_hourly_rate })}</div>
                                    </div>
                                    <div className="table-row">
                                        <div className="table-cell-vehicle">{cellCheckBox({ name: "has_hiab_hourly_rate", value: allValues?.has_hiab_hourly_rate, label: vehicleTypeHiab })}</div>
                                        <div className="table-cell-textfield-left">{cellTexField({ name: "hourly_rate_hiab", value: allValues?.hourly_rate_hiab, checked: allValues?.has_hiab_hourly_rate })}</div>
                                        <div className="table-cell-textfield-right">{cellTexField({ name: "overtime_hourly_rate_hiab", value: allValues?.overtime_hourly_rate_hiab, checked: allValues?.has_hiab_hourly_rate })}</div>
                                    </div>
                                    <div className="table-row">
                                        <div className="table-cell-vehicle">{cellCheckBox({ name: "has_hiab_payroll_hourly_rate", value: allValues?.has_hiab_payroll_hourly_rate, label: vehicleTypeHiabPayroll })}</div>
                                        <div className="table-cell-textfield-left">{cellTexField({ name: "hourly_rate_hiab_payroll", value: allValues?.hourly_rate_hiab_payroll, checked: allValues?.has_hiab_payroll_hourly_rate })}</div>
                                        <div className="table-cell-textfield-right">{cellTexField({ name: "overtime_hourly_rate_hiab_payroll", value: allValues?.overtime_hourly_rate_hiab_payroll, checked: allValues?.has_hiab_payroll_hourly_rate })}</div>
                                    </div>
                                    <div className="table-row">
                                        <div className="table-cell-vehicle">{cellCheckBox({ name: "has_seven_tons_hourly_rate", value: allValues?.has_seven_tons_hourly_rate, label: vehicleTypeSevenTons })}</div>
                                        <div className="table-cell-textfield-left">{cellTexField({ name: "hourly_rate_seven_tons", value: allValues?.hourly_rate_seven_tons, checked: allValues?.has_seven_tons_hourly_rate })}</div>
                                        <div className="table-cell-textfield-right">{cellTexField({ name: "overtime_hourly_rate_seven_tons", value: allValues?.overtime_hourly_rate_seven_tons, checked: allValues?.has_seven_tons_hourly_rate })}</div>
                                    </div>
                                    <div className="table-row">
                                        <div className="table-cell-vehicle">{cellCheckBox({ name: "has_loft_l_hourly_rate", value: allValues?.has_loft_l_hourly_rate, label: vehicleTypeLoftL })}</div>
                                        <div className="table-cell-textfield-left">{cellTexField({ name: "hourly_rate_loft_l", value: allValues?.hourly_rate_loft_l, checked: allValues?.has_loft_l_hourly_rate })}</div>
                                        <div className="table-cell-textfield-right">{cellTexField({ name: "overtime_hourly_rate_loft_l", value: allValues?.overtime_hourly_rate_loft_l, checked: allValues?.has_loft_l_hourly_rate })}</div>
                                    </div>
                                    <div className="table-row">
                                        <div className="table-cell-vehicle">{cellCheckBox({ name: "has_special_hourly_rate", value: allValues?.has_special_hourly_rate, label: vehicleTypeSpecial })}</div>
                                        <div className="table-cell-textfield-left">{cellTexField({ name: "hourly_rate_special", value: allValues?.hourly_rate_special, checked: allValues?.has_special_hourly_rate })}</div>
                                        <div className="table-cell-textfield-right">{cellTexField({ name: "overtime_hourly_rate_special", value: allValues?.overtime_hourly_rate_special, checked: allValues?.has_special_hourly_rate })}</div>
                                    </div>
                                    <div className="table-row">
                                        <div className="table-cell-vehicle">{cellCheckBox({ name: "has_moffett_hourly_rate", value: allValues?.has_moffett_hourly_rate, label: vehicleTypeMoffet })}</div>
                                        <div className="table-cell-textfield-left">{cellTexField({ name: "hourly_rate_moffett", value: allValues?.hourly_rate_moffett, checked: allValues?.has_moffett_hourly_rate })}</div>
                                        <div className="table-cell-textfield-right">{cellTexField({ name: "overtime_hourly_rate_moffett", value: allValues?.overtime_hourly_rate_moffett, checked: allValues?.has_moffett_hourly_rate })}</div>
                                    </div>
                                    <div className="table-row">
                                        <div className="table-cell-vehicle">{cellCheckBox({ name: "has_adr_hourly_rate", value: allValues?.has_adr_hourly_rate, label: vehicleTypeAdr })}</div>
                                        <div className="table-cell-textfield-left">{cellTexField({ name: "hourly_rate_adr", value: allValues?.hourly_rate_adr, checked: allValues?.has_adr_hourly_rate })}</div>
                                        <div className="table-cell-textfield-right">{cellTexField({ name: "overtime_hourly_rate_adr", value: allValues?.overtime_hourly_rate_adr, checked: allValues?.has_adr_hourly_rate })}</div>
                                    </div>
                                </div>
                            </div>
                            <Grid item xs={12}>
                                <FormHelperText component="div"><div className="saved-successfully-data-text">{driverDataStatus === DRIVER_UPDATE_SUCCESS ? showSuccessDataMessage : null}</div></FormHelperText>
                                <FormHelperText component="div"><div className="no-data-error-text">{driverDataStatus === DRIVER_UPDATE_FAILED ? errorMessage : null}</div></FormHelperText>
                                <Button
                                    onClick={saveDriverData}
                                    className="mySendButton"
                                    disabled={disabledUpdateButton}
                                    classes={{
                                        root: classes.root,
                                        disabled: classes.disabled,
                                    }}
                                >
                                    {updateButton}
                                </Button>
                            </Grid>
                            <Grid item xs={4} className="grid-to-inputs">
                                <div className="input-empty-space">
                                    <label className="labelCustom">{labelDayOff} - {labelDayOffStart}</label>
                                    <div className="date-select general-width-to-inputs">
                                        <KeyboardDatePicker
                                            disabled
                                            disableToolbar
                                            autoOk
                                            variant="inline"
                                            format={FORMAT_DATE}
                                            margin="normal"
                                            id="date-picker-inline1"
                                            value={date_from}
                                            onChange={(startDate) => {
                                                if (startDate) {
                                                    setStartSelectedDate(DateService.convertDate(startDate));
                                                }
                                            }}
                                            KeyboardButtonProps={{
                                                "aria-label": { labelDatePicker },
                                            }}
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className="input-empty-space">
                                    <label className="labelCustom">{labelDayOff} - {labelDayOffEnd}</label>
                                    <div className="date-select general-width-to-inputs">
                                        <KeyboardDatePicker
                                            disabled
                                            autoOk
                                            disableToolbar
                                            variant="inline"
                                            format={FORMAT_DATE}
                                            margin="normal"
                                            id="date-picker-inline2"
                                            value={date_to}
                                            onChange={(endDate) => {
                                                if (endDate) {
                                                    setEndSelectedDate(DateService.convertDate(endDate));
                                                }
                                            }}
                                            KeyboardButtonProps={{
                                                "aria-label": { labelDatePicker },
                                            }}
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <FormHelperText component="div"><div className="saved-successfully-data-text">{driverDataStatus === DRIVER_HOLIDAY_SUCCESS ? showSuccessDataHolidayMessage : null}</div></FormHelperText>
                                <FormHelperText component="div"><div className="no-data-error-text">{driverDataStatus === DRIVER_HOLIDAY_FAILED ? errorMessage : null}</div></FormHelperText>
                                <Button
                                    onClick={saveHolidayData}
                                    className="mySendButton"
                                    disabled
                                >
                                    {addHolidayButton}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <div className="driver-history-list history-custom-margin">
                                    <div className="labelCustom"><label>{historyTitleText}</label></div>
                                    {histories.length === 0 && <div className="no-data-message"><EventNoteIcon />{labelNoHistory}</div>}
                                    {histories.length > 0 && <DriverHistoryList histories={histories} />}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={7}>
                        <div className="container-right-side">
                            <div className="custom-margin-to-notes">
                                <div className="labelCustom"><label>{labelDriverText} {labelNotesText}</label></div>
                                <TextareaAutosize rowsMin="12" className="driver-notes" placeholder={labelPlaceholderNotesText} onChange={handleAddNote} value={notelist} />
                                <FormHelperText component="div"><div className="no-data-error-text">{driverDataStatus === NOTE_FAILED ? errorMessage : null}</div></FormHelperText>
                                <FormHelperText component="div"><div className="no-data-error-text">{driverNoteErrorText}</div></FormHelperText>
                                <div className="driver-notes-save-button">
                                    <Button
                                        onClick={saveDriverNote}
                                        className="mySendButton"
                                        disabled={disabledNote}
                                        classes={{
                                            root: classes.root,
                                            disabled: classes.disabled,
                                        }}
                                    >
                                        {addHolidayButton}
                                    </Button>
                                </div>
                            </div>
                            <div className="driver-notes-list">
                                {notes.length === 0 && <div className="no-data-message"><EventNoteIcon />{labelNoDriverNote}</div>}
                                {notes.length > 0 && <DriverNotes notes={notes} />}
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            }
        </>
    );
};

DriverDetail.propTypes = {
    driverDetail: PropTypes.object,
    getDriverById: PropTypes.func,
    setDriverData: PropTypes.func,
    setDriverDataNote: PropTypes.func,
    setDriverHoliday: PropTypes.func,
    changeDriverDetails: PropTypes.func,
    getDriverHistories: PropTypes.func,
};

export default withRouter(connect(({
    driverDetail,
    location,
}) => ({
    driverDetail,
    location,
}), {
    getDriverById,
    setDriverData,
    setDriverDataNote,
    setDriverHoliday,
    changeDriverDetails,
    getDriverHistories,
    getLocationList,
})(DriverDetail));
