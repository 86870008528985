import { useContext } from "react";
import LocalizationService, { LocalizedContext } from "../Services/LocalizationService";

/**
 * Returns an object containing localized text for the given localization keys.
 *
 * @param {string[]} localizationKeys - Array of localization keys for which localized text is needed.
 * @returns {Object} An object where each key is a camelCase version of the input keys, and the value is the corresponding localized text.
 */
const useLocalizedArray = (localizationKeys) => {
    const locale = useContext(LocalizedContext);

    const toCamelCase = (str) => {
        return str.replace(/[-_](.)/g, (_, char) => char.toUpperCase());
    };

    return localizationKeys.reduce((acc, key) => {
        const camelCaseKey = toCamelCase(key);
        acc[camelCaseKey] = LocalizationService.getLocalizedText(locale, key);
        return acc;
    }, {});
};

export default useLocalizedArray;
