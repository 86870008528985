import React, { useCallback, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { KeyboardDatePicker } from "@material-ui/pickers";
import DateService from "../../Services/DateService";
import TextField from "@material-ui/core/TextField";
import useLocalized from "../../CustomHooks/useLocalized";
import Typography from "@material-ui/core/Typography";
import { CURRENCY_SYMBOL, FORMAT_DATE } from "../../Constants/options";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import XMark from "../../Assets/xmark.svg";
import useMuiTheme from "../../CustomHooks/useMuiTheme";

const PartnerPopup = ({ action, handleOpenClose, dialogOpen }) => {
    const classes = useMuiTheme();

    const companyNamePlaceholder = useLocalized("add_partner_company_name_placeholder");
    const locationPlaceholder = useLocalized("add_partner_location_placeholder");
    const startDatePlaceholder = useLocalized("add_order_start_date_placeholder");
    const addEmailPlaceholder = useLocalized("add_email_placeholder");
    const dateShouldNotBeBeforeStartDateErrorMessage = useLocalized("date_should_not_be_before_start_date_error_message");
    const partnerSavedMessage = useLocalized("add_partner_success_message");
    const addPlaceHolder = useLocalized("add_button_text");

    const [allValues, setAllValues] = useState({
        partner_contact_emails: [],
        partner_since: DateService.convertDate(new Date())
    });
    const [emails, setEmails] = useState({});
    const [successMessage, setSuccessMessage] = useState("");

    const handleSave = () => {
        action(allValues);
        handleOpenClose();
        setSuccessMessage(partnerSavedMessage);
        setTimeout(() => setSuccessMessage(""), 5000);
        window.location.reload();
    };

    useEffect(() => {
        setAllValues((values) => { return { ...values, partner_contact_emails: Object.values(emails) };});
    }, [emails]);

    const cellTexField = useCallback(({ name }) => {
        return <TextField
            style={{ width: 100, backgroundColor: "white" }}
            type="number"
            placeholder={addPlaceHolder}
            variant='outlined'
            name={name}
            className={classes.root}
            onChange={(e) => setAllValues({ ...allValues, [e.target.name]: e.target.value })}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        {CURRENCY_SYMBOL}
                    </InputAdornment>
                ),
            }} />;
    }, [classes.root, allValues, addPlaceHolder]);

    return (
        <div className="partner-popup">
            <FormHelperText component="div"><div className="saved-successfully-data-text partner-saved-text">{successMessage}</div></FormHelperText>
            <Dialog open={dialogOpen} onClose={handleOpenClose} aria-labelledby="form-dialog-title" maxWidth='md' fullWidth={true}>
                <div className="main-container">
                    <div className='title-container'>
                        <div>
                            <Typography className="title">
                                {useLocalized("add_partner_title")}
                            </Typography>
                            <Typography className="sub-title">
                                {useLocalized("add_partner_subtitle")}
                            </Typography>
                        </div>
                        <img alt='XMark' src={XMark} onClick={() => { handleOpenClose(); }} />
                    </div>
                    <div className='text-field-container'>
                        <div className='add-partner-textfield-container'>
                            <Typography className="text-field-title">
                                {useLocalized("add_order_company_name_title")}
                            </Typography>
                            <TextField
                                type="text"
                                placeholder={useLocalized(companyNamePlaceholder)}
                                className={classes.root}
                                name='company_name'
                                onChange={(e) => setAllValues({ ...allValues, [e.target.name]: e.target.value })}
                                fullWidth
                                variant='outlined'
                            />
                        </div>
                        <div className='add-partner-textfield-container'>
                            <Typography className="text-field-title">
                                {useLocalized("add_order_location_title")}
                            </Typography>
                            <TextField
                                className={classes.root}
                                type="text"
                                name='location'
                                placeholder={useLocalized(locationPlaceholder)}
                                onChange={(e) => setAllValues({ ...allValues, [e.target.name]: e.target.value })}
                                variant='outlined'
                                fullWidth
                            />
                        </div>
                        <div className='add-partner-textfield-container'>
                            <Typography className="text-field-title">
                                {useLocalized("partners_table_head_cell_partner_since")}
                            </Typography>
                            <KeyboardDatePicker
                                className={classes.root}
                                disableToolbar
                                format={FORMAT_DATE}
                                value={allValues.partner_since ?? DateService.getCurrentDate()}
                                variant='inline'
                                inputVariant="outlined"
                                autoOk
                                minDateMessage={dateShouldNotBeBeforeStartDateErrorMessage}
                                placeholder={startDatePlaceholder}
                                onChange={(startDate) => {
                                    if (startDate) {
                                        setAllValues({ ...allValues, partner_since: DateService.convertDate(startDate) });
                                    }
                                }}
                                fullWidth
                            />
                        </div>
                    </div>
                    <div className='text-field-container-emails'>
                        <div className='add-email-textfield-container'>
                            <Typography className="text-field-title">
                                {useLocalized("add_partner_email_title")}
                            </Typography>
                            <TextField
                                type="email"
                                placeholder={addEmailPlaceholder}
                                className={classes.root}
                                name='partner_contact_emails1'
                                onChange={(e) => setEmails({ ...emails, [e.target.name]: e.target.value })}
                                fullWidth
                                variant='outlined'
                            />
                        </div>
                        <div className='add-email-textfield-container'>
                            <div className="text-field-title-placeholder" />
                            <TextField
                                type="email"
                                placeholder={addEmailPlaceholder}
                                className={classes.root}
                                name='partner_contact_emails2'
                                onChange={(e) => setEmails({ ...emails, [e.target.name]: e.target.value })}
                                fullWidth
                                variant='outlined'
                            />
                        </div>
                        <div className='add-email-textfield-container'>
                            <div className="text-field-title-placeholder" />
                            <TextField
                                type="email"
                                placeholder={addEmailPlaceholder}
                                className={classes.root}
                                name='partner_contact_emails3'
                                onChange={(e) => setEmails({ ...emails, [e.target.name]: e.target.value })}
                                fullWidth
                                variant='outlined'
                            />
                        </div>
                        <div className='add-email-textfield-container'>
                            <div className="text-field-title-placeholder" />
                            <TextField
                                type="email"
                                placeholder={addEmailPlaceholder}
                                className={classes.root}
                                name='partner_contact_emails4'
                                onChange={(e) => setEmails({ ...emails, [e.target.name]: e.target.value })}
                                fullWidth
                                variant='outlined'
                            />
                        </div>
                        <div className='add-email-textfield-container'>
                            <div className="text-field-title-placeholder" />
                            <TextField
                                type="email"
                                placeholder={addEmailPlaceholder}
                                className={classes.root}
                                name='partner_contact_emails5'
                                onChange={(e) => setEmails({ ...emails, [e.target.name]: e.target.value })}
                                fullWidth
                                variant='outlined'
                            />
                        </div>
                    </div>
                    <div className='hourly-rate-container'>
                        <div className="table">
                            <div className="table-row">
                                <div className="table-head-vehicle">{useLocalized("vehicle_title")}</div>
                                <div className="table-head">{useLocalized("hourly_rate_title")}</div>
                                <div className="table-head">{useLocalized("overtime_rate_title")}</div>
                            </div>
                            <div className="table-row">
                                <div className="table-cell-vehicle">{useLocalized("vehicle_type_van")}</div>
                                <div className="table-cell">
                                    {cellTexField({ name: "hourly_rate_van" })}
                                </div>
                                <div className="table-cell">
                                    {cellTexField({ name: "overtime_hourly_rate_van" })}
                                </div>
                            </div>
                            <div className="table-row">
                                <div className="table-cell-vehicle">{useLocalized("vehicle_type_van_payroll")}</div>
                                <div className="table-cell">
                                    {cellTexField({ name: "hourly_rate_van_payroll" })}
                                </div>
                                <div className="table-cell">
                                    {cellTexField({ name: "overtime_hourly_rate_van_payroll" })}
                                </div>
                            </div>
                            <div className="table-row">
                                <div className="table-cell-vehicle">{useLocalized("vehicle_type_hiab")}</div>
                                <div className="table-cell">{cellTexField({ name: "hourly_rate_hiab" })}</div>
                                <div className="table-cell">{cellTexField({ name: "overtime_hourly_rate_hiab" })}</div>
                            </div>
                            <div className="table-row">
                                <div className="table-cell-vehicle">{useLocalized("vehicle_type_hiab_payroll")}</div>
                                <div className="table-cell">{cellTexField({ name: "hourly_rate_hiab_payroll" })}</div>
                                <div className="table-cell">{cellTexField({ name: "overtime_hourly_rate_hiab_payroll" })}</div>
                            </div>
                            <div className="table-row">
                                <div className="table-cell-vehicle">{useLocalized("vehicle_type_seven_tons")}</div>
                                <div className="table-cell">{cellTexField({ name: "hourly_rate_seven_tons" })}</div>
                                <div className="table-cell">{cellTexField({ name: "overtime_hourly_rate_seven_tons" })}</div>
                            </div>
                        </div>
                        <div className='vl' />
                        <div className="table">
                            <div className="table-row">
                                <div className="table-head-vehicle">{useLocalized("vehicle_title")}</div>
                                <div className="table-head">{useLocalized("hourly_rate_title")}</div>
                                <div className="table-head">{useLocalized("overtime_rate_title")}</div>
                            </div>
                            <div className="table-row">
                                <div className="table-cell-vehicle">{useLocalized("vehicle_type_loft_l")}</div>
                                <div className="table-cell">{cellTexField({ name: "hourly_rate_loft_l" })}</div>
                                <div className="table-cell">{cellTexField({ name: "overtime_hourly_rate_loft_l" })}</div>
                            </div>
                            <div className="table-row">
                                <div className="table-cell-vehicle">{useLocalized("vehicle_type_special")}</div>
                                <div className="table-cell">{cellTexField({ name: "hourly_rate_special" })}</div>
                                <div className="table-cell">{cellTexField({ name: "overtime_hourly_rate_special" })}</div>
                            </div>
                            <div className="table-row">
                                <div className="table-cell-vehicle">{useLocalized("vehicle_type_moffet")}</div>
                                <div className="table-cell">{cellTexField({ name: "hourly_rate_moffett" })}</div>
                                <div className="table-cell">{cellTexField({ name: "overtime_hourly_rate_moffett" })}</div>
                            </div>
                            <div className="table-row">
                                <div className="table-cell-vehicle">{useLocalized("vehicle_type_adr")}</div>
                                <div className="table-cell">{cellTexField({ name: "hourly_rate_adr" })}</div>
                                <div className="table-cell">{cellTexField({ name: "overtime_hourly_rate_adr" })}</div>
                            </div>
                            <div className="table-row">
                                <div className="table-cell-vehicle"></div>
                            </div>
                        </div>
                    </div>
                    <div className="button-container">
                        <button className='save-button' onClick={() => handleSave()}>{useLocalized("add_partner_button_text")}</button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default PartnerPopup;
