import React from "react";
import PropTypes from 'prop-types';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const CustomSnackbar= ({
    open, 
    message, 
    severity = 'info', 
    onClose, 
    autoHideDuration = 5000, 
    position = { vertical: 'top', horizontal: 'center' } 
}) => {
    return (
        <Snackbar 
            anchorOrigin={position}
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={(event, reason) => {
                if (reason === 'clickaway') return;
                onClose(event, reason);
            }}
        >
            <Alert onClose={onClose} severity={severity}>
                {message}
            </Alert>
        </Snackbar>
    )
}

CustomSnackbar.propTypes = {
    open: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    severity: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
    onClose: PropTypes.func.isRequired,
    autoHideDuration: PropTypes.number,
    position: PropTypes.shape({
      vertical: PropTypes.oneOf(['top', 'bottom']),
      horizontal: PropTypes.oneOf(['left', 'center', 'right']),
    }),
};

export default CustomSnackbar;
