import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import Drivers from '../../Screens/Drivers/Drivers';
import Partners from '../../Screens/Partners/Partners';
import Orders from '../../Screens/Orders/Orders';
import Schedule from '../../Screens/Schedule/Schedule';
import Container from '@material-ui/core/Container';
import NavigationItems from '../Navigation/Navigation';
import DashboardTitle from '../../Components/DashboardTitle/DashboardTitle';
import DriverDetail from '../../Screens/DriverDetail/DriverDetail';
import Timesheets from '../../Screens/Timesheets/Timesheets';
import Invoice from '../../Screens/Invoice/Invoice';
import JobDetail from '../../Screens/JobDetail/JobDetail';
import WeeklyRecord from '../../Screens/WeeklyRecord/WeeklyRecord';
import { logOutAction } from '../../Redux/Actions/LoginAction'

function Dashboard({logOutAction}) {

  const logOut = useCallback(() => {
    logOutAction();
  }, [logOutAction])

  return (
    <div className="pr-container">
      <BrowserRouter>
        <div className="navigationmenu">
          <DashboardTitle />
          <NavigationItems action={logOut} />
        </div>
        <main className="data-container">
          <Container className="data-container-inner" maxWidth="xl">
            <Route path="/drivers" component={Drivers} />
            <Route path='/driver/:driverId' component={DriverDetail} />
            <Route path="/partners" component={Partners} />
            <Route path="/orders" component={Orders} />
            <Route path="/schedule" component={Schedule} />
            <Route path="/timesheets" component={Timesheets} />
            <Route path="/invoice" component={Invoice} />
            <Route path='/job/:scheduleId' component={JobDetail} />
            <Route path="/weekly-record" component={WeeklyRecord} />
          </Container>
        </main>
      </BrowserRouter>
    </div>
  );
}

const mapStateToProps = (state) => {
  const {
    user: { locale, token },
  } = state.user;
  return { locale, token };
};

export default connect(mapStateToProps, { logOutAction })(Dashboard);
