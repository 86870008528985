import { SHOW_SNACKBAR, HIDE_SNACKBAR } from "../ActionTypes";

export const showSnackbar = (message, severity) => ({
  type: SHOW_SNACKBAR,
  payload: { message, severity },
});

export const hideSnackbar = () => ({
  type: HIDE_SNACKBAR,
});
