import React, { useState, useCallback, useEffect } from 'react';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { connect } from 'react-redux';
import { setNoteToTimesheet, changeNote } from '../../Redux/Actions/TimesheetActions';
import useLocalized from '../../Data/Localization';

const TimesheetNote = ({ noteTimesheet, dateFrom, dateTo, driverId, setNoteToTimesheet, changeNote }) => {

    // region States

    const [noteTimesheetAdded, setNote] = useState(noteTimesheet);

    // endregion

    // region Custom hooks

    const driverTimesheetNotePlaceholder = useLocalized('driver_timesheet_note_placeholder');

    // endregion

    // region Handler functions

    const handleAddNoteToTimesheet = useCallback((e) => {
        setNote(e.target.value);
    }, []);

    const handleUpdateNoteSuccess = useCallback((note, driverId) => {
        changeNote(note, driverId);
    }, [changeNote]);

    const saveNoteToDriverTimesheet = useCallback((driverId, dateFrom, dateTo, noteTimesheetAddedNew) => {
        if (noteTimesheetAddedNew !== null && noteTimesheetAddedNew !== '') {
            setNoteToTimesheet(driverId, dateFrom, dateTo, noteTimesheetAddedNew, handleUpdateNoteSuccess);
        }
    }, [setNoteToTimesheet, handleUpdateNoteSuccess]);

    // endregion

    // region UseEffect calls

    useEffect(() => {
        setNote(noteTimesheet);
    }, [noteTimesheet]);

    // endregion

    // region UI Render

    return (
        <TextareaAutosize
            minRows="1"
            label=""
            onChange={handleAddNoteToTimesheet}
            value={noteTimesheetAdded ?? ""}
            className="timesheet-note"
            placeholder={driverTimesheetNotePlaceholder}
            onBlur={() => saveNoteToDriverTimesheet(driverId, dateFrom, dateTo, noteTimesheetAdded)}
        />
    );

    // endregion
}

export default connect(null, { setNoteToTimesheet, changeNote })(TimesheetNote);
