import React, { useState, useEffect } from 'react';
import { CURRENCY_SYMBOL } from '../../Constants/options';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

const CellNumberField = ({ value, j, handleEdit, vehicleType }) => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleBlur = () => {
    if (inputValue !== value) {
      handleEdit(vehicleType, j, inputValue);
    }
  };

  return (
    <TextField
      style={{ width: 80, backgroundColor: 'white' }}
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      InputProps={{
        startAdornment: (
          <InputAdornment style={{ paddingLeft: '5px' }} position="start">
            {CURRENCY_SYMBOL}
          </InputAdornment>
        ),
      }}
      type="number"
    />
  );
};

export default CellNumberField;
