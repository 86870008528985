import React from "react";
import PropTypes from "prop-types";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core";
import PartnerListCell from "../PartnerListCell/PartnerListCell";
import { PartnerListColumnsTypes } from "../../Constants/partnerListColumnTypes";
import useLocalized from "../../Data/Localization";
import useScrollSync from "../../CustomHooks/useScrollSync";

const PartnerListTable = ({
    partners,
    page,
    rowsPerPage,
    handlePartnerDataChange,
    handleChangePage,
    handleChangeRowsPerPage,
    handlePartnerActiveState
}) => {
    useScrollSync("syncscroll");

    const columns = [
        { id: PartnerListColumnsTypes.COMPANY_NAME, label: `${useLocalized("partners_table_head_cell_company_name")}`, class: "driver-name columns-grid partner-name-cell table-list-head-cell" },
        { id: PartnerListColumnsTypes.LOCATION, label: `${useLocalized("partners_table_head_cell_location")}`, class: "columns-grid location-cell table-list-head-cell" },
        { id: PartnerListColumnsTypes.PARTNER_SINCE, label: `${useLocalized("partners_table_head_cell_partner_since")}`, class: "columns-grid partner-since-cell table-list-head-cell" },
        { id: PartnerListColumnsTypes.HOURLY_RATE_VAN, label: `${useLocalized("partners_table_head_cell_van_hourly_rate")}`, class: "columns-grid rate-cell table-list-head-cell" },
        { id: PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_VAN, label: `${useLocalized("partners_table_head_cell_van_overtime_hourly_rate")}`, class: "columns-grid rate-cell table-list-head-cell" },
        { id: "", label: "", class: "columns-grid divider-cell table-list-head-cell" },
        { id: PartnerListColumnsTypes.HOURLY_RATE_VAN_PAYROLL, label: `${useLocalized("partners_table_head_cell_van_payroll_hourly_rate")}`, class: "columns-grid rate-cell table-list-head-cell" },
        { id: PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_VAN_PAYROLL, label: `${useLocalized("partners_table_head_cell_van_payroll_overtime_hourly_rate")}`, class: "columns-grid rate-cell table-list-head-cell" },
        { id: "", label: "", class: "columns-grid divider-cell table-list-head-cell" },
        { id: PartnerListColumnsTypes.HOURLY_RATE_HIAB, label: `${useLocalized("partners_table_head_cell_hiab_hourly_rate")}`, class: "columns-grid rate-cell table-list-head-cell" },
        { id: PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_HIAB, label: `${useLocalized("partners_table_head_cell_hiab_overtime_hourly_rate")}`, class: "columns-grid rate-cell table-list-head-cell" },
        { id: "", label: "", class: "columns-grid divider-cell table-list-head-cell" },
        { id: PartnerListColumnsTypes.HOURLY_RATE_HIAB_PAYROLL, label: `${useLocalized("partners_table_head_cell_hiab_payroll_hourly_rate")}`, class: "columns-grid rate-cell table-list-head-cell" },
        { id: PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_HIAB_PAYROLL, label: `${useLocalized("partners_table_head_cell_hiab_payroll_overtime_hourly_rate")}`, class: "columns-grid rate-cell table-list-head-cell" },
        { id: "", label: "", class: "columns-grid divider-cell table-list-head-cell" },
        { id: PartnerListColumnsTypes.HOURLY_RATE_SEVEN_TONS, label: `${useLocalized("partners_table_head_cell_seven_tons_hourly_rate")}`, class: "columns-grid rate-cell table-list-head-cell" },
        { id: PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_SEVEN_TONS, label: `${useLocalized("partners_table_head_cell_seven_tons_overtime_hourly_rate")}`, class: "columns-grid rate-cell table-list-head-cell" },
        { id: "", label: "", class: "columns-grid divider-cell table-list-head-cell" },
        { id: PartnerListColumnsTypes.HOURLY_RATE_LOFT_L, label: `${useLocalized("partners_table_head_cell_loft_l_hourly_rate")}`, class: "columns-grid rate-cell table-list-head-cell" },
        { id: PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_LOFT_L, label: `${useLocalized("partners_table_head_cell_loft_l_overtime_hourly_rate")}`, class: "columns-grid rate-cell table-list-head-cell" },
        { id: "", label: "", class: "columns-grid divider-cell table-list-head-cell" },
        { id: PartnerListColumnsTypes.HOURLY_RATE_SPECIAL, label: `${useLocalized("partners_table_head_cell_special_hourly_rate")}`, class: "columns-grid rate-cell table-list-head-cell" },
        { id: PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_SPECIAL, label: `${useLocalized("partners_table_head_cell_special_overtime_hourly_rate")}`, class: "columns-grid rate-cell table-list-head-cell" },
        { id: "", label: "", class: "columns-grid divider-cell table-list-head-cell" },
        { id: PartnerListColumnsTypes.HOURLY_RATE_MOFFET, label: `${useLocalized("partners_table_head_cell_moffet_hourly_rate")}`, class: "columns-grid rate-cell table-list-head-cell" },
        { id: PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_MOFFET, label: `${useLocalized("partners_table_head_cell_moffet_overtime_hourly_rate")}`, class: "columns-grid rate-cell table-list-head-cell" },
        { id: "", label: "", class: "columns-grid divider-cell table-list-head-cell" },
        { id: PartnerListColumnsTypes.HOURLY_RATE_ADR, label: `${useLocalized("partners_table_head_cell_adr_hourly_rate")}`, class: "columns-grid rate-cell table-list-head-cell" },
        { id: PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_ADR, label: `${useLocalized("partners_table_head_cell_adr_overtime_hourly_rate")}`, class: "columns-grid rate-cell table-list-head-cell" },
        { id: "", label: "", class: "columns-grid divider-cell table-list-head-cell" },
        { id: PartnerListColumnsTypes.ACTION, label: "", class: "columns-grid rate-cell table-list-head-cell" },
    ];

    /* MARK: - Component render method */
    return (
        <Paper className="default-table-container">
            <TableContainer className="default-table-list-partner">
                <Table className='partner-list-main-table' size="small" stickyHeader aria-label="sticky table">
                    <TableHead className="table-list-head">
                        <TableRow className="scroller syncscroll" name="scrollSyncedElement">
                            {columns.map((column, index) => (
                                <TableCell className={column.class} key={column.id ? column.id : index}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody className="default-table-body syncscroll" name="scrollSyncedElement">
                        {partners.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, j) => {
                            return (
                                <TableRow className="table-fullwidth-row" hover tabIndex={-1} key={j + 1}>
                                    {columns.map((column, index) => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell className={column.class} key={column.id ? column.id : index}>
                                                { (column.id === PartnerListColumnsTypes.COMPANY_NAME || column.id === PartnerListColumnsTypes.LOCATION) && <div className='cell-background'></div> }
                                                <PartnerListCell column={column} value={value} j={page * rowsPerPage + j} partnerId={row.id} onChange={handlePartnerDataChange} handlePartnerActiveState={handlePartnerActiveState} />
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                className="table-pagination"
                rowsPerPageOptions={[12, 25, 100]}
                component="div"
                count={partners.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
};

/* MARK: - Prop types */
PartnerListTable.propTypes = {
    partners: PropTypes.array.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    handlePartnerDataChange: PropTypes.func.isRequired,
    handleChangePage: PropTypes.func.isRequired,
    handleChangeRowsPerPage: PropTypes.func.isRequired,
};

export default PartnerListTable;
