import React, { useCallback } from "react";
import DateService from "../../Services/DateService";
import CellNumberField from "../CellNumberField/CellNumberField";
import CellTextField from "../CellTextField/CellTextField";
import { PartnerListColumnsTypes } from "../../Constants/partnerListColumnTypes";
import Button from "@material-ui/core/Button";
import MoveDriverIcon from "../../Assets/moveDriverIcon.svg";

const editableColumns = [
    PartnerListColumnsTypes.HOURLY_RATE_VAN,
    PartnerListColumnsTypes.HOURLY_RATE_HIAB,
    PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_VAN,
    PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_HIAB,
    PartnerListColumnsTypes.HOURLY_RATE_SPECIAL,
    PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_SPECIAL,
    PartnerListColumnsTypes.HOURLY_RATE_VAN_PAYROLL,
    PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_VAN_PAYROLL,
    PartnerListColumnsTypes.HOURLY_RATE_HIAB_PAYROLL,
    PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_HIAB_PAYROLL,
    PartnerListColumnsTypes.HOURLY_RATE_SEVEN_TONS,
    PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_SEVEN_TONS,
    PartnerListColumnsTypes.HOURLY_RATE_LOFT_L,
    PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_LOFT_L,
    PartnerListColumnsTypes.HOURLY_RATE_MOFFET,
    PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_MOFFET,
    PartnerListColumnsTypes.HOURLY_RATE_ADR,
    PartnerListColumnsTypes.OVERTIME_HOURLY_RATE_ADR,
];

function PartnerListCell({
    column,
    value,
    j,
    partnerId,
    onChange,
    handlePartnerActiveState
}) {

    const handleEdit = useCallback((vehicleType, j, newValue) => {
        onChange(partnerId, vehicleType, j, newValue);
    }, [onChange]);

    if (editableColumns.includes(column.id)) {
        return (
            <CellNumberField
                value={value}
                j={j}
                handleEdit={handleEdit}
                vehicleType={column.id}
            />
        );
    }

    switch (column.id) {
    case PartnerListColumnsTypes.NR:
        return (
            <div>#{j + 1}</div>
        );
    case PartnerListColumnsTypes.LOCATION:
        return (
            <CellTextField
                value={value}
                j={j}
                handleEdit={handleEdit}
                vehicleType={column.id}
            />
        );
    case PartnerListColumnsTypes.COMPANY_NAME:
        return (
            <CellTextField
                value={value}
                j={j}
                handleEdit={handleEdit}
                vehicleType={column.id}
            />
        );
    case PartnerListColumnsTypes.PARTNER_SINCE:
        return (
            <div className="partnerListCellText">{DateService.getSplitDate(value)["year"]}</div>
        );
    case  PartnerListColumnsTypes.ACTION:
        return (
            <Button className='button' onClick={() => handlePartnerActiveState(partnerId)}>
                <img  alt='MoveDriverIcon' src={MoveDriverIcon} />
            </Button>
        );
    default:
        return (
            <div className="partnerListCellText">
                {column.format && typeof value === "number" ? column.format(value) : value}
            </div>
        );
    }
}

export default PartnerListCell;
