import {
    WEEKLY_RECORD_LOADING,
    WEEKLY_RECORD_FAILED,
    WEEKLY_RECORD_SUCCESS,
    WEEKLY_RECORD_DOWNLOAD_LOADING,
    WEEKLY_RECORD_DOWNLOAD_SUCCESS,
    WEEKLY_RECORD_DOWNLOAD_FAILED,
    WEEKLY_RECORD_EMAIL_SEND_LOADING,
    WEEKLY_RECORD_EMAIL_SEND_SUCCESS,
    WEEKLY_RECORD_EMAIL_SEND_FAILED,
    SUCCESS,
    FAIL,
    LOADING,
    DEFAULT,
    PDF_SUCCESS,
    PDF_FAIL,
    PDF_LOADING,
    WEEKLY_RECORD_RELOAD,
} from '../../Redux/ActionTypes';

const initialState = {
    status: DEFAULT,
    errorCode: '',
    weeklyRecord: [],
}

const weeklyRecordReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case WEEKLY_RECORD_LOADING:
            return {
                ...state,
                status: LOADING,
            };
        case WEEKLY_RECORD_SUCCESS:
            return {
                ...state,
                status: SUCCESS,
                weeklyRecord: payload,
            };
        case WEEKLY_RECORD_FAILED:
            return {
                ...state,
                status: FAIL,
            };
        case WEEKLY_RECORD_DOWNLOAD_LOADING:
            return {
                ...state,
                downloadStatus: PDF_LOADING,
            };
        case WEEKLY_RECORD_DOWNLOAD_SUCCESS:
            return {
                ...state,
                downloadStatus: PDF_SUCCESS,
            };
        case WEEKLY_RECORD_DOWNLOAD_FAILED:
            return {
                ...state,
                downloadStatus: PDF_FAIL,
            };
        case WEEKLY_RECORD_EMAIL_SEND_LOADING:
            return {
                ...state,
                emailStatus: PDF_LOADING,
            };
        case WEEKLY_RECORD_EMAIL_SEND_SUCCESS:
            return {
                ...state,
                emailStatus: PDF_SUCCESS,
            };
        case WEEKLY_RECORD_EMAIL_SEND_FAILED:
            return {
                ...state,
                emailStatus: PDF_FAIL,
            };
        case WEEKLY_RECORD_RELOAD:
            return {
                ...state,
                status: DEFAULT,
                emailStatus: DEFAULT,
                downloadStatus: DEFAULT, 
            };
        default:
            return state;
    }
};

export default weeklyRecordReducer;
