import { useEffect } from 'react';

const useScrollSync = (className) => {
  useEffect(() => {
    const elems = document.getElementsByClassName(className);
    const names = {};

    // Clear existing listeners
    for (const name in names) {
      if (names.hasOwnProperty(name)) {
        for (let i = 0; i < names[name].length; i++) {
          names[name][i].removeEventListener('scroll', names[name][i].syn);
        }
      }
    }

    // Set up new listeners
    for (let i = 0; i < elems.length;) {
      let found = 0;
      let j = 0;
      let el = elems[i++];
      const name = el.getAttribute('name');

      if (!name) continue;

      el = el.scroller || el;

      // Check if element already exists in names array
      for (; j < (names[name] = names[name] || []).length;) {
        found |= names[name][j++] === el;
      }

      if (!found) {
        names[name].push(el);
      }

      el.eX = el.eY = 0;

      (function (el, name) {
        el.addEventListener('scroll', (el.syn = () => {
          const elems = names[name];
          const scrollX = el.scrollLeft;
          const scrollY = el.scrollTop;
          const xRate = scrollX / (el.scrollWidth - el.clientWidth);
          const yRate = scrollY / (el.scrollHeight - el.clientHeight);

          const updateX = scrollX !== el.eX;
          const updateY = scrollY !== el.eY;

          el.eX = scrollX;
          el.eY = scrollY;

          for (let i = 0; i < elems.length;) {
            const otherEl = elems[i++];
            if (otherEl !== el) {
              if (updateX) {
                otherEl.scrollLeft = Math.round(
                  xRate * (otherEl.scrollWidth - otherEl.clientWidth)
                );
              }
              if (updateY) {
                otherEl.scrollTop = Math.round(
                  yRate * (otherEl.scrollHeight - otherEl.clientHeight)
                );
              }
            }
          }
        }));
      })(el, name);
    }

    // Cleanup function to remove event listeners
    return () => {
      for (const name in names) {
        if (names.hasOwnProperty(name)) {
          for (let i = 0; i < names[name].length; i++) {
            names[name][i].removeEventListener('scroll', names[name][i].syn);
          }
        }
      }
    };
  }, [className]);
};

export default useScrollSync;
