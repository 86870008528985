export const ADD = 'ADD';
export const SUBTRACT = 'SUBTRACT';

// Login action types
export const LOGGING_IN_LOADING = 'LOGGING_IN_LOADING ';
export const LOGGING_IN_SUCCESS = 'LOGGING_IN_SUCCESS';
export const LOGGING_IN_FAILED = 'LOGGING_IN_FAILED';
export const SET_LOGIN_TO_DEFAULT = 'SET_LOGIN_TO_DEFAULT ';
export const LOG_OUT = 'LOG_OUT';

// Generic action types
export const DEFAULT = 'DEFAULT';
export const LOADING = 'LOADING';
export const SUCCESS = 'SUCCESS';
export const FAIL = 'FAIL';

export const PDF_LOADING = 'PDF_LOADING';
export const PDF_SUCCESS = 'PDF_SUCCESS';
export const PDF_FAIL = 'PDF_FAIL';

// Driver action types
export const DRIVERS_LOADING = 'DRIVERS_LOADING';
export const DRIVERS_SUCCESS = 'DRIVERS_SUCCESS';
export const DRIVERS_FAIL = 'DRIVERS_FAIL';

// Partner action types
export const PARTNERS_LOADING = 'PARTNERS_LOADING';
export const PARTNERS_SUCCESS = 'PARTNERS_SUCCESS';
export const PARTNERS_FAIL = 'PARTNERS_FAIL';

// Partner action types
export const PARTNERS_DATA_LOADING = 'PARTNERS_DATA_LOADING';
export const PARTNERS_DATA_SUCCESS = 'PARTNERS_DATA_SUCCESS';
export const PARTNERS_DATA_FAILED = 'PARTNERS_DATA_FAILED';

// Partner action types
export const PARTNERS_UPDATE_LOADING = 'PARTNERS_UPDATE_LOADING';
export const PARTNERS_UPDATE_SUCCESS = 'PARTNERS_UPDATE_SUCCESS';
export const PARTNERS_UPDATE_FAILED = 'PARTNERS_UPDATE_FAILED';

// Partner action types
export const PARTNER_CREATE_LOADING = 'PARTNER_CREATE_LOADING';
export const PARTNER_CREATE_SUCCESS = 'PARTNER_CREATE_SUCCESS';
export const PARTNER_CREATE_FAILED = 'PARTNER_CREATE_FAILED';

//DRIVER Data action types
export const DRIVER_DATA_LOADING = 'DRIVER_DATA_LOADING ';
export const DRIVER_DATA_SUCCESS = 'DRIVER_DATA_SUCCESS';
export const DRIVER_DATA_FAILED = 'DRIVER_DATA_FAILED';

export const PARTNER_CHANGE = 'PARTENER_CHANGE';

//DRIVER Data action types
export const DRIVER_UPDATE_LOADING = 'DRIVER_UPDATE_LOADING ';
export const DRIVER_UPDATE_SUCCESS = 'DRIVER_UPDATE_SUCCESS';
export const DRIVER_UPDATE_FAILED = 'DRIVER_UPDATE_FAILED';

export const DRIVER_STATUS_UPDATE_LOADING = 'DRIVER_STATUS_UPDATE_LOADING ';
export const DRIVER_STATUS_UPDATE_SUCCESS = 'DRIVER_STATUS_UPDATE_SUCCESS';
export const DRIVER_STATUS_UPDATE_FAILED = 'DRIVER_STATUS_UPDATE_FAILED';

export const DRIVER_CHANGE = 'DRIVER_CHANGE';

export const CHANGE_DRIVER_DETAILS = 'CHANGE_DRIVER_DETAILS';
export const DRIVER_DETAIL_RELOAD = 'DRIVER_DETAIL_RELOAD';

export const DRIVER_HISTORIES_LOADING = 'DRIVER_HISTORIES_LOADING';
export const DRIVER_HISTORIES_SUCCESS = 'DRIVER_HISTORIES_SUCCESS';
export const DRIVER_HISTORIES_FAILED = 'DRIVER_HISTORIES_FAILED';

export const DRIVER_HISTORIES_DELETE_LOADING = 'DRIVER_HISTORIES_DELETE_LOADING';
export const DRIVER_HISTORIES_DELETE_SUCCESS = 'DRIVER_HISTORIES_DELETE_SUCCESS';
export const DRIVER_HISTORIES_DELETE_FAILED = 'DRIVER_HISTORIES_DELETE_FAILED';

//ORDER Data action types
export const ORDERS_LOADING = 'ORDERS_LOADING';
export const ORDERS_SUCCESS = 'ORDERS_SUCCESS';
export const ORDERS_FAIL = 'ORDERS_FAIL';
export const ORDER_DELETE = 'ORDERS_DELETE';

//ORDER delete action types
export const ORDER_DELETE_LOADING = 'ORDER_DELETE_LOADING';
export const ORDER_DELETE_SUCCESS = 'ORDER_DELETE_SUCCESS';
export const ORDER_DELETE_FAIL = 'ORDER_DELETE_FAIL';

//Order create action types
export const ORDER_CREATE_LOADING = 'ORDER_CREATE_LOADING'
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS'
export const ORDER_CREATE_FAIL = 'ORDER_CREATE_FAIL'

// Driver NOTE action types
export const NOTE_LOADING = 'NOTE_LOADING';
export const NOTE_SUCCESS = 'NOTE_SUCCESS';
export const NOTE_FAILED = 'NOTE_FAILED';

// Driver delete HISTORY action types
export const DRIVER_DELETE_LOADING = 'DRIVER_DELETE_LOADING';
export const DRIVER_DELETE_SUCCESS = 'DRIVER_DELETE_SUCCESS';
export const DRIVER_DELETE_FAILED = 'DRIVER_DELETE_FAILED';

export const DRIVER_HOLIDAY_SUCCESS = 'DRIVER_HOLIDAY_SUCCESS';
export const DRIVER_HOLIDAY_FAILED = 'DRIVER_HOLIDAY_FAILED';

export const DRIVER_STAND_BY_SUCCESS = 'DRIVER_STAND_BY_SUCCESS';
export const DRIVER_STAND_BY_FAILED = 'DRIVER_STAND_BY_FAILED';

export const DRIVER_STAND_BY_DELETE_SUCCESS = 'DRIVER_STAND_BY_DELETE_SUCCESS';
export const DRIVER_STAND_BY_DELETE_FAILED = 'DRIVER_STAND_BY_DELETE_FAILED';

// Driver schedules action types
export const SCHEDULES_LOADING = 'SCHEDULES_LOADING';
export const SCHEDULES_SUCCESS = 'SCHEDULES_SUCCESS';
export const SCHEDULES_FAILED = 'SCHEDULES_FAILED';

export const GET_AVAILABLE_ORDER_LOADING = 'GET_AVAILABLE_ORDER_LOADING';
export const GET_AVAILABLE_ORDER_SUCCESS = 'GET_AVAILABLE_ORDER_SUCCESS';
export const GET_AVAILABLE_ORDER_FAILED = 'GET_AVAILABLE_ORDER_FAILED';

export const DELETE_SCHEDULE_LOADING = 'DELETE_SCHEDULE_LOADING';
export const DELETE_SCHEDULE_SUCCESS = 'DELETE_SCHEDULE_SUCCESS';
export const DELETE_SCHEDULE_FAILED = 'DELETE_SCHEDULE_FAILED';

export const CREATE_SCHEDULE_LOADING = 'CREATE_SCHEDULE_LOADING';
export const CREATE_SCHEDULE_SUCCESS = 'CREATE_SCHEDULE_SUCCESS';
export const CREATE_SCHEDULE_FAILED = 'CREATE_SCHEDULE_FAILED';

export const UPDATE_SCHEDULE_STATUS_LOADING = 'UPDATE_SCHEDULE_STATUS_LOADING';
export const UPDATE_SCHEDULE_STATUS_SUCCESS = 'UPDATE_SCHEDULE_STATUS_SUCCESS';
export const UPDATE_SCHEDULE_STATUS_FAILED = 'UPDATE_SCHEDULE_STATUS_FAILED';

export const SCHEDULE_RELOAD = 'SCHEDULE_RELOAD';

// Driver timesheet action types
export const TIMESHEETS_LOADING = 'TIMESHEETS_LOADING';
export const TIMESHEETS_SUCCESS = 'TIMESHEETS_SUCCESS';
export const TIMESHEETS_FAILED = 'TIMESHEETS_FAILED';

export const TIMESHEET_UPDATE_LOADING = 'TIMESHEET_UPDATE_LOADING ';
export const TIMESHEET_UPDATE_SUCCESS = 'TIMESHEET_UPDATE_SUCCESS';
export const TIMESHEET_UPDATE_FAILED = 'TIMESHEET_UPDATE_FAILED';

export const TIMESHEET_BY_DRIVER_LOADING = 'TIMESHEET_BY_DRIVER_LOADING ';
export const TIMESHEET_BY_DRIVER_SUCCESS = 'TIMESHEET_BY_DRIVER_SUCCESS';
export const TIMESHEET_BY_DRIVER_FAILED = 'TIMESHEET_BY_DRIVER_FAILED';

export const DRIVER_TIMESHEET_NOTE_LOADING = 'DRIVER_TIMESHEET_NOTE_LOADING ';
export const DRIVER_TIMESHEET_NOTE_SUCCESS = 'DRIVER_TIMESHEET_NOTE_SUCCESS';
export const DRIVER_TIMESHEET_NOTE_FAILED = 'DRIVER_TIMESHEET_NOTE_FAILED';

export const INVOICES_LOADING = 'INVOICES_LOADING ';
export const INVOICES_SUCCESS = 'INVOICES_SUCCESS';
export const INVOICES_FAILED = 'INVOICES_FAILED';
export const INVOICE_RELOAD = 'INVOICE_RELOAD';

export const INVOICE_UPDATE_LOADING = 'INVOICE_UPDATE_LOADING';
export const INVOICE_UPDATE_SUCCESS = 'INVOICE_UPDATE_SUCCESS';
export const INVOICE_UPDATE_FAILED = 'INVOICE_UPDATE_FAILED';

export const INVOICE_CHANGE = 'INVOICE_CHANGE';
export const JOB_DATA_LOADING = 'JOB_DATA_LOADING ';
export const JOB_DATA_SUCCESS = 'JOB_DATA_SUCCESS';
export const JOB_DATA_FAILED = 'JOB_DATA_FAILED';

export const JOB_DATA_RELOAD = 'JOB_DATA_RELOAD';

export const NOTE_CHANGE = 'NOTE_CHANGE';
export const TIMESHEET_CHANGE = 'TIMESHEET_CHANGE';
export const WEEKLY_RECORD_RELOAD = 'WEEKLY_RECORD_RELOAD';
export const WEEKLY_RECORD_FAILED = 'WEEKLY_RECORD_FAILED';
export const WEEKLY_RECORD_LOADING = 'WEEKLY_RECORD_LOADING';
export const WEEKLY_RECORD_SUCCESS = 'WEEKLY_RECORD_SUCCESS';

export const WEEKLY_RECORD_DOWNLOAD_FAILED = 'WEEKLY_RECORD_DOWNLOAD_FAILED';
export const WEEKLY_RECORD_DOWNLOAD_LOADING = 'WEEKLY_RECORD_DOWNLOAD_LOADING';
export const WEEKLY_RECORD_DOWNLOAD_SUCCESS = 'WEEKLY_RECORD_DOWNLOAD_SUCCESS';

export const WEEKLY_RECORD_EMAIL_SEND_FAILED = 'WEEKLY_EMAIL_SEND_FAILED';
export const WEEKLY_RECORD_EMAIL_SEND_LOADING = 'WEEKLY_RECORD_EMAIL_SEND_LOADING';
export const WEEKLY_RECORD_EMAIL_SEND_SUCCESS = 'WEEKLY_RECORD_EMAIL_SEND_SUCCESS';

// Location Constants
export const LOCATION_LOADING = 'LOCATION_LOADING';
export const LOCATION_FAILED = 'LOCATION_FAILED';
export const LOCATION_SUCCESS = 'LOCATION_SUCCESS';

// Snackbar action types
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR';
