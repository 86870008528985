import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';

const CellTextField = ({ value, j, handleEdit, vehicleType }) => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleBlur = () => {
    if (inputValue !== value) {
      handleEdit(vehicleType, j, inputValue);
    }
  };

  return (
    <TextField
      style={{ backgroundColor: 'white', marginLeft: 10 }}
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      type="text"
    />
  );
};

export default CellTextField;
