import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import useLocalized from '../../Data/Localization';
import { OverlayTrigger, Button } from 'react-bootstrap';
import FormControl from '@material-ui/core/FormControl';
import { Popover } from 'react-bootstrap';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { getAvailableOrdersByDateSync, getSchedule } from '../../Redux/Actions/ScheduleActions';
import { setStateToDefaultJob } from '../../Redux/Actions/JobDataAction';
import { scheduleStatuses } from '../../Constants';
import DateService from '../../Services/DateService';
import { Link } from 'react-router-dom';
import {
  setDriverHoliday,
  getDriverHistories,
  getDriverById,
  setDriverStandBy,
  deleteDriverStandBy,
} from '../../Redux/Actions/DriverDataAction';
import { withRouter } from 'react-router-dom';
import WebService from '../../Services/WebServices';
import { DRIVERS, DRIVER_HISTORIES } from '../../Constants/options';
import { deleteDriverHistory } from '../../Redux/Actions/DriverDataAction';
import { format } from 'date-fns';

import {
    DIM_GRAY,
    ACTIVEBLUE,
    LIME_GREEN,
    DONE_BLUE,
    RED, STAND_BY_ORANGE,
} from '../../Styles/colors';

const DriverCalendar = ({
    getDriverHistories,
    setDriverHoliday,
    setDriverStandBy,
    deleteDriverStandBy,
    standById,
    day,
    date,
    scheduleId,
    driverId,
    companyName,
    companyAddress,
    status,
    handleDeleteSchedule,
    createOrUpdateSchedule,
    updateScheduleStatus,
    driverHoliday,
    driverStandBy,
    getSchedule,
    deleteDriverHistory,
    getDriverById,
  }) => {
    const labelRemove = useLocalized('remove_button');
    const sendButton = useLocalized('send_button');
    const selectWarehouseLabel = useLocalized('select_warehouse');
    const selectHolidayLabel = useLocalized('select_holiday');
    const selectStandByLabel = useLocalized('select_stand_by');
    const selectWarehouseId = "selectWarehouseHouse";
    const selectHolidayId = "selectHoliday";
    const selectStandById = "selectStandBy";
    const details = useLocalized('details_button');

    const dateFrom = DateService.convertDate(DateService.getWeekStartDate(new Date()));
    const dateTo = DateService.convertDate(DateService.getWeekEndDate(new Date()));

    const selectWarehouse = useMemo(() => ({
        id: selectWarehouseId,
        company_name: selectWarehouseLabel,
        location: null
    }), [selectWarehouseLabel]);

    const selectHoliday = useMemo(() => ({
        id: selectHolidayId,
        company_name: selectHolidayLabel,
        location: null
    }), [selectHolidayLabel]);

    const selectStandBy = useMemo(() => ({
      id: selectStandById,
      company_name: selectStandByLabel,
      location: null
    }), [selectStandByLabel]);

    const selectedSchedule = useMemo(() => ({
        id: scheduleId,
        company_name: companyName,
        location: companyAddress
    }), [companyAddress, companyName, scheduleId]);

    const getDefaultSchedule = useCallback(() => {
      if (driverHoliday) {
        return [selectHoliday]
      }

      if (driverStandBy) {
        return [selectStandBy]
      }

        return [selectWarehouse, selectedSchedule]
    }, [driverHoliday, driverStandBy, selectHoliday, selectStandBy, selectWarehouse, selectedSchedule]);

    const [availableOrders, setOrders] = useState([]);

    useEffect(() => {
        setOrders(getDefaultSchedule());
    }, [scheduleId, companyName, driverHoliday, getDefaultSchedule]);

    const handleOpen = useCallback(async () => {
        let orders = await getAvailableOrdersByDateSync(date)
        if (orders) {
            if (scheduleId) {
                orders.unshift(selectedSchedule)
            }
            orders.unshift(selectWarehouse)
            setOrders(orders)
        }
        else {
            setOrders([selectWarehouse])
        }
    }, [date, scheduleId, selectWarehouse, selectedSchedule]);

    const changeSelected = useCallback((event) => {
      switch (event.target.value) {
        case selectWarehouseId:
          if (scheduleId) {
            handleDeleteSchedule(scheduleId, driverId);
          }
          break;

      case "Off":
        setDriverHoliday(driverId, date, date, () => {
          getSchedule(dateFrom, dateTo);
        });
      break;

      case "stand_by":
        setDriverStandBy(driverId, date, () => {
          getSchedule(dateFrom, dateTo);
        });
      break;

      default:
        createOrUpdateSchedule({
          value: event.target.value,
          driverId,
          date,
          scheduleId,
        });
      }
    }, [scheduleId, setDriverHoliday, driverId, date, setDriverStandBy, createOrUpdateSchedule, handleDeleteSchedule, getSchedule, dateFrom, dateTo]);

    const removeHoliday = useCallback(async () => {
        try {
            let options = {
              method: 'GET',
              path: `${DRIVERS}/${driverId}${DRIVER_HISTORIES}`,
            };

            const driverHistories = await WebService.getSharedInstance().callWebservice(
              options,
            );

            const filteredHistorys = driverHistories.filter(word => word.event_type == "DAY OFF")
            const testDays = ["M", "Tu", "W", "Th", "Fr", "Sa", "Su"]

            filteredHistorys.forEach(element => {

                var date = new Date(dateFrom);
                date.setDate(date.getDate() + testDays.indexOf(day));

                const testDate = format(date, "dd.MM.yyyy");
                const result = element.description.match("[0-9]{2}([\-/ \.])[0-9]{2}[\-/ \.][0-9]{4}");

                if (result["0"] == testDate) {
                    deleteDriverHistory(element.id)
                }
            });

            getSchedule(dateFrom, dateTo);
          } catch (err) {
              return
          }
    }, [dateFrom, day, deleteDriverHistory, driverId, dateTo, getSchedule])

    const sendButtonBackGroundColorCheck = useCallback(() => {
        if (driverHoliday || driverStandBy) {
            return RED;
        } else if (!status) {
            return DIM_GRAY;
        } else if (status === scheduleStatuses.PLANNED) {
            return ACTIVEBLUE;
        } else if (status === scheduleStatuses.SENT) {
            return LIME_GREEN;
        } else if (status === scheduleStatuses.DONE) {
            return DONE_BLUE;
        }
    }, [driverHoliday, driverStandBy, status]);

    const checkSendButtonIsDisabled = useCallback(() => {
      if (driverHoliday) {
        return false
      }

      if (driverStandBy) {
        return false
      }

      return status !== scheduleStatuses.PLANNED || DateService.checkIfDateIsInPast(date)
    }, [driverHoliday, driverStandBy, status, date]);

    const checkWarehouseSelectIsDisabled = useCallback(() => {
        return status === scheduleStatuses.SENT || status === scheduleStatuses.DONE || driverHoliday || driverStandBy || DateService.checkIfDateIsInPast(date)
    }, [date, driverHoliday, driverStandBy, status]);

    const getDefaultSelection = useCallback(() => {
      if (driverHoliday) {
        return selectHolidayId;
      }

      if (driverStandBy) {
        return selectStandById;
      }

      if (scheduleId) {
        return scheduleId;
      }

      return selectWarehouseId
    }, [driverHoliday, driverStandBy, scheduleId]);

    const getSendButtonText = useCallback(() => {
        if (driverHoliday || driverStandBy) {
            return labelRemove;
        }
        if (!status || status === scheduleStatuses.PLANNED) {
            return sendButton;
        }
        return status
    }, [driverHoliday, driverStandBy, status, labelRemove, sendButton]);

    const handleScheduleStatusUpdate = useCallback((newStatus) => {
      if (driverHoliday) {
        getDriverHistories(driverId);
        removeHoliday();
        getSchedule(dateFrom, dateTo);

        return
      }

      if (driverStandBy) {
        getDriverHistories(driverId);
        deleteDriverStandBy(standById, () => {
          getSchedule(dateFrom, dateTo);
        });

        return
      }

      updateScheduleStatus(scheduleId, newStatus, driverId)
    }, [driverHoliday, driverStandBy, updateScheduleStatus, scheduleId, driverId, getDriverHistories, removeHoliday, getSchedule, dateFrom, dateTo, deleteDriverStandBy, standById]);

    const handleRemoveScheduleStatusUpdate = useCallback(() => {
        if (scheduleId) {
            handleDeleteSchedule(scheduleId, driverId)
        }
    }, [driverId, handleDeleteSchedule, scheduleId]);


    const checkPopoverIsDisabled = useCallback(() => {
        return scheduleId == null
    }, [scheduleId]);

    const popoverRight = (
        <Popover className="popover-custom" variant="popover-style" onClick={() => document.body.click()} id="popover">
            <div className="simplepopover-to-edit">
                {status !== 'Planned' && <div className="detail-button"><Link to={`/job/${scheduleId}`} onClick={() => setStateToDefaultJob()} className="link-details">{details}</Link></div>}
                <div className="remove-button">
                    <div className="link-remove"
                        onClick={() => handleRemoveScheduleStatusUpdate()}
                    >{labelRemove}
                    </div>
                </div>
            </div>
        </Popover>
    );

    return (
        <div className="week-col">
            <div className="day-name"><strong>{day}</strong></div>
            <div className="select-warehouse">
                <FormControl className="general-width-to-inputs">
                    <Select
                        onOpen={handleOpen}
                        className="general-width-to-inputs select-warehouse-width"
                        onChange={changeSelected}
                        disabled={checkWarehouseSelectIsDisabled()}
                        value={getDefaultSelection()}
                    >
                        {availableOrders
                            .filter(item => item.company_name)
                            .sort((a, b) => {
                                if (a.company_name === selectWarehouseLabel) return -1;
                                if (b.company_name === selectWarehouseLabel) return 1;
                                return a.company_name.localeCompare(b.company_name);
                            })
                            .map(item => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.company_name} {item.location}
                                </MenuItem>
                            ))}
                        <MenuItem style={{ color: STAND_BY_ORANGE }} key="standByOption" value="stand_by">
                            Stand By
                        </MenuItem>
                        <MenuItem style={{ color: RED }} key={driverId} value="Off">
                            Day Off
                        </MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div>
                <Button
                    className="mySendButton scheduleButton"
                    style={{ backgroundColor: sendButtonBackGroundColorCheck() }}
                    disabled={checkSendButtonIsDisabled()}
                    onClick={() => handleScheduleStatusUpdate(scheduleStatuses.SENT)}
                >
                    <span>{getSendButtonText()}</span>
                </Button>
            </div>
            <div>
                <OverlayTrigger
                    container={this}
                    trigger="click"
                    placement="right"
                    overlay={popoverRight}
                    rootClose={true}
                >
                    <Button variant="edit" disabled={checkPopoverIsDisabled()}>
                        <span className="circle-list"></span>
                        <span className="circle-list"></span>
                        <span className="circle-list"></span>
                    </Button>
                </OverlayTrigger>
            </div>
        </div>
    );
}

export default withRouter(connect(({ driverDetail }) => ({ driverDetail }), {
  deleteDriverHistory,
  setStateToDefaultJob,
  setDriverHoliday,
  setDriverStandBy,
  getSchedule,
  getDriverHistories,
  getDriverById,
  deleteDriverStandBy
})(DriverCalendar));
