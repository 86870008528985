import React, { useCallback, useEffect, useState } from 'react';
import useLocalized from '../../Data/Localization';
import { LOADING, FAIL } from '../../Redux/ActionTypes';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TopTitle from '../../Components/TopTitle/TopTitle';
import { useWebServiceWithDate, useErrorHandlerWithAlertAndPageReload } from '../../CustomHooks';
import { getSchedule, deleteSchedule, createOrUpdateSchedule, updateScheduleStatus, setStateToDefault, updateNextDayScheduleStatuses } from '../../Redux/Actions/ScheduleActions';
import DriverSchedule from '../../Components/DriverSchedule/DriverSchedule';
import SearchInput from '../../Components/SearchInput/SearchInput';
import DateService from '../../Services/DateService';
import ErrorIcon from '@material-ui/icons/Error';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorMessage from '../../Components/ErrorMessage/ErrorMessage';
import ScheduleService from '../../Services/ScheduleService';
import 'react-day-picker/lib/style.css';
import WeekSelector from '../../Components/WeekSelector/WeekSelector';

const Schedule = ({
  schedules: {
    status: scheduleStatus,
    data: scheduleData,
    errorCode
  },
  getSchedule,
  deleteSchedule,
  createOrUpdateSchedule,
  updateScheduleStatus,
  setStateToDefault,
  updateNextDayScheduleStatuses
}) => {
  const [filteredScheduleData, setFilteredScheduleData] = useState({ scheduleData: [] });

  const [searchedForText, setSearchedForText] = useState(false);

  const [currentDate] = useState(new Date());

  const [dateFrom, setStartSelectedDate] = useState(DateService.convertDate(DateService.getWeekStartDate(currentDate)));
  const [dateTo, setEndSelectedDate] = useState(DateService.convertDate(DateService.getWeekEndDate(currentDate)));

  const sendNextDayButton = useLocalized('send_next_day');
  const searchErrorMsg = useLocalized('searchErrorMessage');

  const currentWeek = DateService.getCurrentWeekNumber(DateService.getCurrentDate());

  const [selectedWeek, setSelectedWeek] = useState(currentWeek);

  const handleSearch = (event) => {
    if (event.key !== 'Enter') {
      return;
    }
    let searchText = event.target.value;
    const searchedScheduleData = scheduleData.scheduleData.filter(({
      driver_details: {
        driver_name,
      },
    }) => driver_name.toLowerCase().includes(searchText.toLowerCase()));
    setFilteredScheduleData({ scheduleData: searchedScheduleData });
    setSearchedForText(true);
  }

  const handleDeleteSchedule = useCallback((scheduleId, driverId) => {
    deleteSchedule(scheduleId, driverId)
  }, [deleteSchedule]);

  const handleCreateSchedule = useCallback((orderId, driverId, date, scheduleId) => {
    createOrUpdateSchedule(orderId, driverId, date, scheduleId)
  }, [createOrUpdateSchedule]);

  const handleUpdateScheduleStatus = useCallback((scheduleId, status, driverId) => {
    updateScheduleStatus(scheduleId, status, driverId)
  }, [updateScheduleStatus]);

  const handleSendAllSchedules = useCallback(() => {
    const nexDaySchedules = ScheduleService.getNextDaySchedules(scheduleData)
    updateNextDayScheduleStatuses(nexDaySchedules)

  }, [scheduleData, updateNextDayScheduleStatuses]);

  useWebServiceWithDate(scheduleStatus, getSchedule, dateFrom, dateTo);

  useEffect(() => {
    setTimeout(() => {
      getSchedule(dateFrom, dateTo);
    }, 0);
  }, [dateFrom, dateTo, getSchedule]);

  useEffect(() => {
    setFilteredScheduleData(scheduleData);
  }, [scheduleData])

  useErrorHandlerWithAlertAndPageReload(scheduleStatus, errorCode);

  const [state, setState] = useState({
    hoverRange: undefined,
    selectedDays: [],
  });

  const updateWeekSelection = useCallback((weekNumber, days) => {
    setSelectedWeek(weekNumber);
    setStartSelectedDate(DateService.convertDate(days[0]));
    setEndSelectedDate(DateService.convertDate(days[6]));
    setStateToDefault();
    setState({
      selectedDays: days,
    });
  }, [setStateToDefault]);

  const { hoverRange, selectedDays } = state;

  const daysAreSelected = selectedDays.length > 0;

  const modifiers = {
    hoverRange,
    selectedRange: daysAreSelected && {
      from: selectedDays[0],
      to: selectedDays[6],
    },
    hoverRangeStart: hoverRange && hoverRange.from,
    hoverRangeEnd: hoverRange && hoverRange.to,
    selectedRangeStart: daysAreSelected && selectedDays[0],
    selectedRangeEnd: daysAreSelected && selectedDays[6],
  };

  return (
    <>
      <TopTitle title={`${useLocalized('nav_schedule')}`} subtitle={`${useLocalized('schedule_subtitle')}`} />
      <Grid container spacing={3}>
        <div className="right-button-select-section">
          <Grid item xs={12} className="start-end-date-send-button">
            <SearchInput handleSearch={handleSearch} />
            <WeekSelector 
              selectedWeek={selectedWeek} 
              selectedDays={selectedDays} 
              dateFrom={dateFrom} 
              dateTo={dateTo} 
              modifiers={modifiers} 
              updateWeekSelection={updateWeekSelection} 
            />
            <Button
              className="mySendButton send_nexd_day_button"
              onClick={() => handleSendAllSchedules()}
            >
              {sendNextDayButton}
            </Button>
          </Grid>
        </div>
      </Grid>
      {filteredScheduleData.scheduleData && filteredScheduleData.scheduleData.length > 0 ? (
        <DriverSchedule
          schedules={filteredScheduleData}
          dateFrom={dateFrom}
          weekNumber={selectedWeek}
          handleDeleteSchedule={handleDeleteSchedule}
          createOrUpdateSchedule={handleCreateSchedule}
          updateScheduleStatus={handleUpdateScheduleStatus}
        />
      ) : (
        searchedForText && <div className="search-error-msg"><p>{searchErrorMsg}</p></div>
      )}
      {scheduleStatus === LOADING && scheduleData.length === 0 && <div className="loading-icon"><CircularProgress /></div>}
      {scheduleStatus === FAIL && <div className="no-data-error-text"><ErrorIcon /><ErrorMessage /></div>}
    </>
  );
}

Schedule.propTypes = {
  schedules: PropTypes.object,
  getSchedule: PropTypes.func,
  deleteSchedule: PropTypes.func,
  createOrUpdateSchedule: PropTypes.func,
  updateScheduleStatus: PropTypes.func,
  setStateToDefault: PropTypes.func,
  updateNextDayScheduleStatuses: PropTypes.func
};

export default connect(({
  schedules,
}) => ({
  schedules,
}), {
  getSchedule,
  deleteSchedule,
  createOrUpdateSchedule,
  updateScheduleStatus,
  setStateToDefault,
  updateNextDayScheduleStatuses
})(Schedule);
