const timesheetVehicleType = {
    VAN: 'Van',
    HIAB: 'Hiab',
    SPECIAL: 'Special',
    VAN_PAYROLL: 'Van_Payroll',
    HIAB_PAYROLL: 'Hiab_Payroll',
    SEVEN_TONS: 'Seven_Tons',
    LOFT_L: 'Loft_L',
    ADR: 'ADR',
    MOFFETT: 'Moffett'
};

export default timesheetVehicleType;
