import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import driverReducer from './Reducers/DriverReducer';
import driverDataReducer from './Reducers/DriverDataReducer';
import userReducer from './Reducers/UserReducer';
import partnersReducer from './Reducers/PartnerReducer';
import partnerDataReducer from './Reducers/PartnerDataReducer';
import schedulesReducer from './Reducers/ScheduleReducer';
import ordersReducer from './Reducers/OrderReducer';
import timesheetsReducer from './Reducers/TimesheetReducer';
import invoiceReducer from './Reducers/InvoiceReducer';
import jobDataReducer from './Reducers/JobDataReducer';
import weeklyRecordReducer from './Reducers/WeeklyRecordReducer';
import locationReducer from './Reducers/LocationReducer';
import snackbarReducer from './Reducers/SnackbarReducer';
import { persistStore, persistReducer } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const reducer = combineReducers({
  user: userReducer,
  drivers: driverReducer,
  driverDetail: driverDataReducer,
  partners: partnersReducer,
  partnerDetail: partnerDataReducer,
  schedules: schedulesReducer,
  timesheets: timesheetsReducer,
  orders: ordersReducer,
  invoices: invoiceReducer,
  jobDetail: jobDataReducer,
  weeklyRecord: weeklyRecordReducer,
  location: locationReducer,
  snackbar: snackbarReducer
});

const persistConfig = {
  key: 'ltlwrk',
  storage: localStorage,
  whitelist: ['user'],
}

const persistedReducer = persistReducer(persistConfig, reducer)

export const store = createStore(
    persistedReducer,
    composeEnhancer(applyMiddleware(thunk)),
)

export const persistor = persistStore(store);
