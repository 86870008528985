import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import DateService from "../../Services/DateService";
import { CURRENCY_SYMBOL } from "../../Constants/options";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { changeInvoice, updateInvoiceData } from "../../Redux/Actions/InvoiceActions";

const useStyles = makeStyles({
    accordion: {
        backgroundColor: "white",
        boxShadow: "0px 0px 6px 4px rgba(0,0,0,0.04)",
        width: "fit-content",
    }
});

function CompanyInvoiceList({ invoiceValues, invoiceId, warehouse, partnerLocation, totalAmount, purchaseOrderNr, invoiceNr, statusInvoice, invoiceDays, dateFrom, dateTo, changeInvoice, updateInvoiceData, preinvoiceSent, poReceived, invoiceSent, inProgress, invoiceDescription, hasPayZone }) {

    const classes = useStyles();

    const [invoiceNewNr, setInvoiceNr] = useState(invoiceNr);

    const handleChangeInvoiceNr = useCallback((event) => {
        setInvoiceNr(event.target.value);
    }, []);

    const [orderNr, setOrderNr] = useState(purchaseOrderNr);

    const handleChangeOrderNr = useCallback((event) => {
        setOrderNr(event.target.value);
    }, []);

    const handleUpdateInvoiceSuccess = useCallback((orderNr) => {
        changeInvoice(invoiceId, orderNr);
    }, [changeInvoice, invoiceId]);

    const handleUpdateInvoiceNrSuccess = useCallback((invoiceNewNr) => {
        changeInvoice(invoiceId, invoiceNewNr);
    }, [changeInvoice, invoiceId]);

    const handleUpdateInvoiceStatusSuccess = useCallback((invoiceNewNr) => {
        changeInvoice(invoiceId, invoiceNewNr);
    }, [changeInvoice, invoiceId]);

    useEffect(() => {
        setInvoiceNr(invoiceNr);
        setOrderNr(purchaseOrderNr);
    }, [invoiceNr, purchaseOrderNr, invoiceValues]);

    const changeInvoiceOrderNr = useCallback((event) => {
        let value = orderNr;
        if(orderNr === null){
            value = "";
        }
        updateInvoiceData(invoiceId, { purchase_order_nr: value }, handleUpdateInvoiceSuccess);
    }, [invoiceId, orderNr, updateInvoiceData, handleUpdateInvoiceSuccess]);

    const changeInvoiceNr = useCallback((event) => {
        let value = invoiceNewNr;
        if(invoiceNewNr === null){
            value = "";
        }
        updateInvoiceData(invoiceId, { invoice_nr: value }, handleUpdateInvoiceNrSuccess);
    }, [invoiceId, invoiceNewNr, updateInvoiceData, handleUpdateInvoiceNrSuccess]);

    const changeInvoiceStatus = useCallback((event) => {
        updateInvoiceData(invoiceId, { status: event.target.value }, handleUpdateInvoiceStatusSuccess);
    }, [invoiceId, updateInvoiceData, handleUpdateInvoiceStatusSuccess]);

    const CompanyInvoiceListMemo = useMemo(() => {
        return (
            <Accordion className={classes.accordion} key={invoiceId}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-label="Expand"
                    aria-controls="additional-actions1-content"
                    key={invoiceId}
                >
                    <div className="invoice-content-section">
                        <div className="warehouse-col">{warehouse} {partnerLocation}</div>
                        <div className="van-col">
                            <div className="multiple-label-in-header">
                                <div className="hours-col">{invoiceValues.total_hours_van}</div>
                                <div className="hours-overtime-col">{invoiceValues.total_overtime_hours_van}</div>
                            </div>
                        </div>
                        <div className="hiab-col">
                            <div className="multiple-label-in-header">
                                <div className="hours-col">{invoiceValues.total_hours_van_payroll}</div>
                                <div className="hours-overtime-col">{invoiceValues.total_overtime_hours_van_payroll}</div>
                            </div>
                        </div>
                        <div className="hiab-col">
                            <div className="multiple-label-in-header">
                                <div className="hours-col">{invoiceValues.total_hours_hiab}</div>
                                <div className="hours-overtime-col">{invoiceValues.total_overtime_hours_hiab}</div>
                            </div>
                        </div>
                        <div className="hiab-col">
                            <div className="multiple-label-in-header">
                                <div className="hours-col">{invoiceValues.total_hours_hiab_payroll}</div>
                                <div className="hours-overtime-col">{invoiceValues.total_overtime_hours_hiab_payroll}</div>
                            </div>
                        </div>
                        <div className="hiab-col">
                            <div className="multiple-label-in-header">
                                <div className="hours-col">{invoiceValues.total_hours_seven_tons}</div>
                                <div className="hours-overtime-col">{invoiceValues.total_overtime_hours_seven_tons}</div>
                            </div>
                        </div>
                        <div className="hiab-col">
                            <div className="multiple-label-in-header">
                                <div className="hours-col">{invoiceValues.total_hours_loft_l}</div>
                                <div className="hours-overtime-col">{invoiceValues.total_overtime_hours_loft_l}</div>
                            </div>
                        </div>
                        <div className="hiab-col">
                            <div className="multiple-label-in-header">
                                <div className="hours-col">{invoiceValues.total_hours_special}</div>
                                <div className="hours-overtime-col">{invoiceValues.total_overtime_hours_special}</div>
                            </div>
                        </div>
                        <div className="hiab-col">
                            <div className="multiple-label-in-header">
                                <div className="hours-col">{invoiceValues.total_hours_moffett}</div>
                                <div className="hours-overtime-col">{invoiceValues.total_overtime_hours_moffett}</div>
                            </div>
                        </div>
                        <div className="hiab-col">
                            <div className="multiple-label-in-header">
                                <div className="hours-col">{invoiceValues.total_hours_adr}</div>
                                <div className="hours-overtime-col">{invoiceValues.total_overtime_hours_adr}</div>
                            </div>
                        </div>
                        <div className="total-amount-col">
                            {hasPayZone ? <div className="dot" /> : <div className="dot-inactive" />}
                        </div>
                        <div className="total-amount-col">{CURRENCY_SYMBOL}{totalAmount}</div>
                        <div className="order-nr-col">
                            <FormControlLabel
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                control={<TextField
                                    value={orderNr}
                                    placeholder='-'
                                    onChange={handleChangeOrderNr}
                                    onBlur={changeInvoiceOrderNr}
                                    key={invoiceId}
                                />}
                                label=""
                            />
                        </div>
                        <div className="invoice-nr-col">
                            <FormControlLabel
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                control={<TextField
                                    value={invoiceNewNr}
                                    placeholder='-'
                                    onChange={handleChangeInvoiceNr}
                                    onBlur={changeInvoiceNr}
                                    key={invoiceId}
                                />}
                                label=""
                            />
                        </div>
                        <div className="status-col">
                            <FormControlLabel
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                control={<Select defaultValue='PreInvoiceSent' key={invoiceId} name='status' className='invoice-status-select' value={statusInvoice} onChange={changeInvoiceStatus}>
                                    <MenuItem value='InProgress'>{inProgress}</MenuItem>
                                    <MenuItem value='PreInvoiceSent'>{preinvoiceSent}</MenuItem>
                                    <MenuItem value='POReceived'>{poReceived}</MenuItem>
                                    <MenuItem value='InvoiceSent'>{invoiceSent}</MenuItem>
                                </Select>
                                }
                                label=""
                            />
                        </div>
                    </div>
                </AccordionSummary>
                <div className="invoice-divider"><Divider /></div>
                <AccordionDetails className={classes.accordionContent} key={invoiceId}>
                    <div className="invoice-details">
                        <div className="invoice-description-label">{invoiceDescription}</div>
                        <div className="date-range">{DateService.getSplitDate(dateFrom)["year"]} - {DateService.getSplitDate(dateTo)["year"]}</div>
                        <div className="invoice-day-details">
                            {invoiceDays.map((invoiceday) =>
                                <div className="invoice-content-section">
                                    <div className="warehouse-col">
                                        {DateService.getDayName(invoiceday.date)}
                                        : {invoiceday.drivers}
                                    </div>
                                    <div className="van-col">
                                        <div className="multiple-label-in-header">
                                            <div className="hours-col">{invoiceday.total_daily_hours_van}</div>
                                            <div className="hours-overtime-col">{invoiceday.total_daily_overtime_hours_van}</div>
                                        </div>
                                    </div>
                                    <div className="van-col">
                                        <div className="multiple-label-in-header">
                                            <div className="hours-col">{invoiceday.total_daily_hours_van_payroll}</div>
                                            <div className="hours-overtime-col">{invoiceday.total_daily_overtime_hours_van_payroll}</div>
                                        </div>
                                    </div>
                                    <div className="hiab-col">
                                        <div className="multiple-label-in-header">
                                            <div className="hours-col">{invoiceday.total_daily_hours_hiab}</div>
                                            <div className="hours-overtime-col">{invoiceday.total_daily_overtime_hours_hiab}</div>
                                        </div>
                                    </div>
                                    <div className="hiab-col">
                                        <div className="multiple-label-in-header">
                                            <div className="hours-col">{invoiceday.total_daily_hours_hiab_payroll}</div>
                                            <div className="hours-overtime-col">{invoiceday.total_daily_overtime_hours_hiab_payroll}</div>
                                        </div>
                                    </div>
                                    <div className="hiab-col">
                                        <div className="multiple-label-in-header">
                                            <div className="hours-col">{invoiceday.total_daily_hours_seven_tons}</div>
                                            <div className="hours-overtime-col">{invoiceday.total_daily_overtime_hours_seven_tons}</div>
                                        </div>
                                    </div>
                                    <div className="hiab-col">
                                        <div className="multiple-label-in-header">
                                            <div className="hours-col">{invoiceday.total_daily_hours_loft_l}</div>
                                            <div className="hours-overtime-col">{invoiceday.total_daily_overtime_hours_loft_l}</div>
                                        </div>
                                    </div>
                                    <div className="hiab-col">
                                        <div className="multiple-label-in-header">
                                            <div className="hours-col">{invoiceday.total_daily_hours_special}</div>
                                            <div className="hours-overtime-col">{invoiceday.total_daily_overtime_hours_special}</div>
                                        </div>
                                    </div>
                                    <div className="hiab-col">
                                        <div className="multiple-label-in-header">
                                            <div className="hours-col">{invoiceday.total_daily_hours_moffett}</div>
                                            <div className="hours-overtime-col">{invoiceday.total_daily_overtime_hours_moffett}</div>
                                        </div>
                                    </div>
                                    <div className="hiab-col">
                                        <div className="multiple-label-in-header">
                                            <div className="hours-col">{invoiceday.total_daily_hours_adr}</div>
                                            <div className="hours-overtime-col">{invoiceday.total_daily_overtime_hours_adr}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="tax-zone-col">{invoiceday.invoice_details_pay_zones.map(zone => zone.zone_name).join(", ")}</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        );
    }, [classes.accordion, classes.accordionContent, invoiceId, warehouse, partnerLocation, invoiceValues.total_hours_van, invoiceValues.total_overtime_hours_van, invoiceValues.total_hours_van_payroll, invoiceValues.total_overtime_hours_van_payroll, invoiceValues.total_hours_hiab, invoiceValues.total_overtime_hours_hiab, invoiceValues.total_hours_hiab_payroll, invoiceValues.total_overtime_hours_hiab_payroll, invoiceValues.total_hours_seven_tons, invoiceValues.total_overtime_hours_seven_tons, invoiceValues.total_hours_loft_l, invoiceValues.total_overtime_hours_loft_l, invoiceValues.total_hours_special, invoiceValues.total_overtime_hours_special, invoiceValues.total_hours_adr, invoiceValues.total_overtime_hours_adr, invoiceValues.total_hours_moffett, invoiceValues.total_overtime_hours_moffett, hasPayZone, totalAmount, orderNr, handleChangeOrderNr, changeInvoiceOrderNr, invoiceNewNr, handleChangeInvoiceNr, changeInvoiceNr, statusInvoice, changeInvoiceStatus, inProgress, preinvoiceSent, poReceived, invoiceSent, invoiceDescription, dateFrom, dateTo, invoiceDays]);

    return CompanyInvoiceListMemo;
}

export default connect(null, { changeInvoice, updateInvoiceData })(CompanyInvoiceList);
